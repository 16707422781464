import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Modal, Table } from './styles';

interface IModalProps {
  show: boolean;
  onHide: () => void;
  enrolled_id: number;
}

interface ITurnsData {
  id: number;
  date: string;
  horary: string;
  confirmed: string;
  created_at: string;
}

interface ITurnResponse {
  data: ITurnsData[];
  from: number;
  to: number;
  total: number;
}

const Turns: React.FC<IModalProps> = ({ show, onHide, enrolled_id }) => {
  const [turns, setTurns] = useState<ITurnsData[]>([]);
  useEffect(() => {
    api
      .get<ITurnResponse>(`enrolleds/${enrolled_id}/turns`, {
        params: { perPage: 10 },
      })
      .then((response) => {
        const data = response.data.data.map((turnsData, index) => ({
          index: response.data.to - response.data.from + (index + 1),
          id: turnsData.id,
          date: format(parseISO(turnsData.date), 'dd/MM/yyyy HH:mm'),
          horary: turnsData.horary,
          confirmed: turnsData.confirmed ? 'Sí' : 'No',
          created_at: format(
            parseISO(turnsData.created_at),
            'dd/MM/yyyy HH:mm'
          ),
        }));
        setTurns(data);
      });
  }, [enrolled_id]);

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Modal.Header className="border-none" closeButton>
        <h4>Turnos del alumno</h4>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Horario</th>
              <th>¿Entregue?</th>
              <th>Inscripción</th>
            </tr>
          </thead>
          <tbody>
            {(turns.length > 0 && (
              <>
                {turns.map((rowData) => (
                  <tr>
                    <td>{rowData.date}</td>
                    <td>{rowData.horary}</td>
                    <td>{rowData.confirmed}</td>
                    <td>{rowData.created_at}</td>
                  </tr>
                ))}
              </>
            )) || (
              <tr>
                <td colSpan={4}>Ningun turno agendado</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="border-none">
        <div className="buttons-group">
          <button type="button" className="btn-cancel me-2" onClick={onHide}>
            Cerrar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default Turns;

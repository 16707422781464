import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import Input from '~/components/Input';

import { Modal } from './styles';
import Select, { IOption } from '~/components/Select';

interface IDeliverData {
  id: number;
  period: string;
  date: string;
  basic_basket: number;
  cereal: number;
  cookie: number;
  colporteur: number;
  turns_number: number;
  begin_turn: string;
  end_turn: string;
}

interface IModalProps {
  show: boolean;
  onHide: () => void;
  deliverParams: IDeliverData;
  onRefreshTable: (refreshTable: boolean) => void;
}

const FormEdit: React.FC<IModalProps> = ({
  show,
  onHide,
  onRefreshTable,
  deliverParams,
}) => {
  const options: IOption[] = [
    { id: 0, value: 'No', selected: false },
    { id: 1, value: 'Sí', selected: false },
  ];
  const formEditCertificationRef = useRef<FormHandles>(null);
  const [basicBaskets, setBasicBaskets] = useState<IOption[]>(options);
  const [cookies, setCookies] = useState<IOption[]>(options);
  const [cereals, setCereals] = useState<IOption[]>(options);
  const [colporteurs, setColporteurs] = useState<IOption[]>(options);

  const handleChangeBasketBasic = useCallback(
    (selectedOption) => {
      setBasicBaskets(() => {
        return basicBaskets.map((data) => {
          return {
            ...data,
            selected: data.id === selectedOption.id,
          };
        });
      });
    },
    [basicBaskets]
  );

  const handleChangeCookie = useCallback(
    (selectedOption) => {
      const newCookies = cookies.map((data) => {
        return {
          ...data,
          selected: data.id === selectedOption.id,
        };
      });
      setCookies(newCookies);
    },
    [cookies]
  );

  const handleChangeCereal = useCallback(
    (selectedOption) => {
      setCereals(() => {
        return cereals.map((data) => {
          return {
            ...data,
            selected: data.id === selectedOption.id,
          };
        });
      });
    },
    [cereals]
  );

  const handleChangeColporteur = useCallback(
    (selectedOption) => {
      setColporteurs(() => {
        return colporteurs.map((data) => {
          return {
            ...data,
            selected: data.id === selectedOption.id,
          };
        });
      });
    },
    [colporteurs]
  );

  const handleSubmitEditCertification = useCallback(
    async (data: IDeliverData) => {
      try {
        formEditCertificationRef.current?.setErrors({});

        const schema = Yup.object().shape({
          date: Yup.string().required('La fecha de entrega es necesario'),
          turns_number: Yup.string().required(
            'La cantidad de turnos es necesario'
          ),
          begin_turn: Yup.string().required(
            'El incio de los turnos es obligatório'
          ),
          end_turn: Yup.string().required(
            'El término de los turnos es obligatório'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        deliverParams.id
          ? await api.put(`/deliveries/${deliverParams.id}`, data)
          : await api.post(`/deliveries `, data);

        onHide();

        if (onRefreshTable) {
          onRefreshTable(true);
        }

        deliverParams.id
          ? Toast.fire({
              icon: 'success',
              title: 'Cadastro alterado com sucesso!',
            })
          : Toast.fire({
              icon: 'success',
              title: 'Cadastro efetuado com sucesso!',
            });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEditCertificationRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Error al guardar los datos', 'error');
        }
      }
    },
    [onHide, onRefreshTable, deliverParams.id]
  );

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form
        ref={formEditCertificationRef}
        initialData={deliverParams}
        onSubmit={handleSubmitEditCertification}
        className="p-4"
      >
        <Modal.Header className="border-none" closeButton>
          {deliverParams.id ? (
            <h4>Editar registro de entrega</h4>
          ) : (
            <h4>Añadir fecha de entrega</h4>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div className="col-lg-6 mb-3">
              <label>Fecha de entrega</label>
              <Input type="datetime-local" name="date" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Bolsón de alimentos</label>
              <Select
                name="basic_basket"
                options={basicBaskets}
                placeholder="Selecione"
                onChange={handleChangeBasketBasic}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Galletita</label>
              <Select
                name="cookie"
                options={cookies}
                placeholder="Selecione"
                onChange={handleChangeCookie}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Cereal</label>
              <Select
                name="cereal"
                options={cereals}
                placeholder="Selecione"
                onChange={handleChangeCereal}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Colportor</label>
              <Select
                name="colporteur"
                options={colporteurs}
                placeholder="Selecione"
                onChange={handleChangeColporteur}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Cantidad de turnos</label>
              <Input type="number" name="turns_number" step="10" min="0" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Apertura turnera online</label>
              <Input type="datetime-local" name="begin_turn" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Cierre turnera online</label>
              <Input type="datetime-local" name="end_turn" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Cancelar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Guardar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormEdit;

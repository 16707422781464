import React from 'react';

import TableUsers from './Partials/Table';

const Users: React.FC = () => {
  return (
    <>
      <TableUsers />
    </>
  );
};

export default Users;

import React from 'react';

import TableInscriptionPeriod from './Partials/Table';

const RegistrationPeriod: React.FC = () => {
  return (
    <>
      <TableInscriptionPeriod />
    </>
  );
};

export default RegistrationPeriod;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { BsTrash } from 'react-icons/bs';
import Swal from 'sweetalert2';

import { BiEditAlt } from 'react-icons/bi';
import { usePermission } from '~/hooks/Permission';
import api from '~/services/api';
import Toast from '~/utils/toast';

import FormEdit from '../Formulario';
import DataTable from '~/components/DataTable';
import Loading from '~/components/Loading';

interface IUsersResponse {
  id: number;
  name: string;
  email: string;
}

interface IResponse {
  data: IUsersResponse[];
  current_page: number;
  total: number;
  from: number;
}

interface IUserData {
  index: number;
  id: number;
  name: string;
  email: string;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

const Table: React.FC = () => {
  const { permission } = usePermission();
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState<IUserData[]>([]);
  const [userSelected, setUserSelected] = useState<IUserData>({} as IUserData);
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });
  const [column, setColumn] = useState('');
  const [request, setrequest] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>('users', {
        params: {
          page: pageSelected,
          search,
          column,
          request,
          perPage,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((userData, index) => ({
          index: response.data.from + index,
          id: userData.id,
          name: userData.name,
          email: userData.email,
        }));

        setUsers(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSelected, search, column, request, perPage]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable]);

  const handleDeleteCheckUser = useCallback(
    async (user_id) => {
      try {
        await api.delete(`users/${user_id}`);
        const newUser = users.filter((user) => user.id !== user_id);
        setUsers(newUser);
        loadDatatable();
        Toast.fire({
          icon: 'success',
          title: 'Usuário borrado!',
        });
      } catch (error) {
        Swal.fire('Opss...', 'Erro al eliminar usuario', 'error');
      }
    },
    [loadDatatable, users]
  );

  const handleClickDeleteUser = useCallback(
    (user: IUsersResponse) => {
      Swal.fire({
        text: `Desea eliminar este usuário?`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#2e90fd',
        cancelButtonColor: '#d3d3d3',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeleteCheckUser(user.id);
        }
      });
    },
    [handleDeleteCheckUser]
  );

  const handleSearch = useCallback(
    (value) => {
      setSearch(value);
      setPageSelected(pageSelected);
    },
    [pageSelected]
  );

  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setrequest(datatableParams.sortorder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  const handleAddUser = useCallback(() => {
    setUserSelected({} as IUserData);
    setShowEdit(true);
  }, []);

  const handleLoadParamEdit = useCallback(
    (refreshTable: boolean) => {
      if (refreshTable) {
        loadDatatable();
        setShowEdit(false);
      }
    },
    [loadDatatable]
  );

  const handleEditStudent = useCallback(async (studentData: IUserData) => {
    setUserSelected(studentData);
    setShowEdit(true);
  }, []);

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
        width: 10,
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: (cell: number, row) => {
          return (
            <div className="w-100">
              {permission >= 2 && (
                <>
                  <button
                    type="button"
                    onClick={() => handleEditStudent(row)}
                    className="bg-transparent border-0"
                  >
                    <BiEditAlt
                      size={24}
                      color="#3624f5"
                      title="Cambiar datos"
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleClickDeleteUser(row)}
                    className="bg-transparent border-0"
                  >
                    <BsTrash size={24} color="#F4000F" title="Borrar usuario" />
                  </button>
                </>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'name',
        text: 'Nombre',
        sort: true,
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
      },
    ];
  }, [handleClickDeleteUser, handleEditStudent, permission]);

  return (
    <>
      <div className="py-5 px-sm-5">
        <div className="row">
          <div className="col-12 px-4 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={users}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onSearch={handleSearch}
              onTableChange={handleChangePage}
              onAddButonClick={handleAddUser}
              loading={loading}
              defaultSorted={[
                {
                  dataField: 'lastname',
                  order: 'asc',
                },
              ]}
              searchable
              showAddButton
            />
          </div>
        </div>
      </div>
      <FormEdit
        show={showEdit}
        onHide={() => setShowEdit(false)}
        userParams={userSelected}
        onRefreshTable={handleLoadParamEdit}
      />
      <Loading show={loading} message="Cargando datos..." />
    </>
  );
};

export default Table;

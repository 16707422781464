import styled from 'styled-components';

export const FormFilter = styled.div`
  .buttons-group {
    button {
      width: 121px;
      height: 40px;
      border-radius: 10px;
      border: 2px solid transparent;
    }

    button[type='button'] {
      color: #d9d9d9;
      font-weight: 500;
      border-color: #d9d9d9;
    }

    button[type='submit'] {
      color: #ffffff;
      font-weight: bold;
      background: #2e90fd;
    }
  }
`;

import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import Input from '~/components/Input';

import { Modal } from './styles';

interface IUserData {
  id: number;
  name: string;
  email: string;
}
interface IModalProps {
  show: boolean;
  onHide: () => void;
  userParams: IUserData;
  onRefreshTable: (refreshTable: boolean) => void;
}

const FormEdit: React.FC<IModalProps> = ({
  show,
  onHide,
  onRefreshTable,
  userParams,
}) => {
  const formUserRef = useRef<FormHandles>(null);

  const handleSubmitEditCertification = useCallback(
    async (data: IUserData) => {
      try {
        formUserRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('El nombre es obligatório'),
          email: Yup.string().required('El correo obligatório'),
          password: Yup.string().required('La contraseña es obligatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        userParams.id
          ? await api.put(`users/${userParams.id}`, data)
          : await api.post(`users/`, data);

        onHide();

        if (onRefreshTable) {
          onRefreshTable(true);
        }

        userParams.id
          ? Toast.fire({
              icon: 'success',
              title: 'Registro cambiado con suceso!',
            })
          : Toast.fire({
              icon: 'success',
              title: 'Registro añadido con suceso!',
            });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formUserRef.current?.setErrors(errors);
        } else if (error?.response.data?.message) {
          Swal.fire('Opss...', error?.response.data?.message, 'error');
        } else {
          Swal.fire('Opss...', 'Erro ao guardar los datos', 'error');
        }
      }
    },
    [onHide, onRefreshTable, userParams.id]
  );

  return (
    <Modal show={show} onHide={onHide} close>
      <Form
        ref={formUserRef}
        initialData={userParams}
        onSubmit={handleSubmitEditCertification}
        className="p-4"
      >
        <Modal.Header className="border-none" closeButton>
          {userParams.id ? (
            <h4>Editar datos del usuário</h4>
          ) : (
            <h4>Añadir usuário</h4>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div>
              <label>Nombre *</label>
              <Input type="text" name="name" />
            </div>
          </div>
          <div className="row mb-3">
            <div>
              <label>Correo *</label>
              <Input type="email" name="email" />
            </div>
          </div>
          <div className="row mb-3">
            <div>
              <label>Contraseña *</label>
              <Input type="password" name="password" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Cancelar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Guardar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormEdit;

import React from 'react';

import { Loading as LoadingComponent } from './styles';

interface ITableProps {
  show: boolean;
  message: string;
}

const Loading: React.FC<ITableProps> = ({ show, message }) => {
  return (
    <>
      <LoadingComponent active={show}>
        <div className="loading-box d-flex flex-column align-items-center justify-content-center">
          <div className="spinner-border text-light" role="status" />
          <span className="d-block fw-bold">{message}</span>
        </div>
      </LoadingComponent>
    </>
  );
};

export default Loading;

import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import Input from '~/components/Input';

import { Modal } from './styles';
import getValidationErros from '~/utils/getValidationsErrors';

interface IFilterData {
  name: string;
  lastname: string;
  document: number;
  enrollment: string;
}

interface IFiltersProps {
  show: boolean;
  onHide: () => void;
  onLoadFilterParam: ({ name, document }: IFilterData) => void;
  onFilterClean: (clear: boolean) => void;
}

const Filters: React.FC<IFiltersProps> = ({
  show,
  onHide,
  onLoadFilterParam,
  onFilterClean,
}) => {
  const formFilterRef = useRef<FormHandles>(null);

  const handleSendFilterParam = useCallback(
    async (data: IFilterData) => {
      try {
        formFilterRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome necessário'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (onLoadFilterParam) {
          onLoadFilterParam(data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formFilterRef.current?.setErrors(errors);
        } else {
          Swal.fire('Ops...', 'Erro ao pesquisar', 'error');
        }
      }
    },
    [onLoadFilterParam]
  );

  const handleClearFilter = useCallback(() => {
    if (formFilterRef.current) {
      formFilterRef.current.reset();
    }
    if (onFilterClean) {
      onFilterClean(true);
    }
  }, [onFilterClean]);

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form
        ref={formFilterRef}
        onSubmit={handleSendFilterParam}
        className="p-2"
      >
        <Modal.Header className="border-none" closeButton>
          <h4>Filtros</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label>Nombre</label>
              <Input type="text" name="name" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Apellido</label>
              <Input type="text" name="lastname" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Documento</label>
              <Input type="phone" name="document" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Matricula</label>
              <Input type="text" name="enrollment" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group col-12 my-2 d-flex justify-content-end">
            <button
              type="button"
              onClick={handleClearFilter}
              className="btn btn-secondary me-2"
            >
              Limpiar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Pesquisar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Filters;

import React from 'react';
import { ContainerComponent } from './styles';

const Container: React.FC = (props) => {
  return (
    <ContainerComponent className="overflow-auto">
      {props.children}
    </ContainerComponent>
  );
};

export default Container;

import { darken } from 'polished';
import styled from 'styled-components';

interface ILoading {
  active?: boolean;
  fixed?: boolean;
}

export const Container = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 25px;

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 20px;
    color: #31344e;
  }

  button.add-button {
    background: #2e90fd;
    color: #ffffff;
    border-radius: 10px;
    font-weight: 700;
    border: none;
  }

  button.back-button {
    background: #fff;
    color: #777;
    border-radius: 10px;
    font-weight: 700;
    border: 1px solid #999;
  }

  button.btn-action {
    background: #f0f1f9;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    border: none;
  }

  button.add-button:hover,
  button.back-button:hover,
  button.btn-action:hover {
    background-color: ${darken(0.05, '#fff')};
  }

  .react-bootstrap-table table {
    table-layout: auto;
  }
`;

export const Input = styled.div`
  background: #f9f9f9;
  border-radius: 19px;
  width: 100%;
  padding: 5px 15px;

  input {
    background: none;
    border: none;
    color: #acacac;
    width: calc(100% - 24px);

    ::placeholder {
      color: #acacac;
    }
  }

  @media screen and (min-width: 992px) {
    width: 320px;
  }
`;

export const Loading = styled.div<ILoading>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  span {
    color: #fff !important;
  }
`;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';

import { usePermission } from '~/hooks/Permission';
import api from '~/services/api';

import DataTable from '~/components/DataTable';
import Loading from '~/components/Loading';
import { useDelivery } from '../../../Context/Index';
import ModalFile from '~/components/ModalFile';
import generateUrlBlob from '~/utils/generateUrlBlob';
import Toast from '~/utils/toast';

interface IParams {
  delivery_id: string;
}

interface IDeliverResponse {
  id: number;
  horary: string;
  confirmed: number;
  created_at: string;
  enrollment: string;
  name: string;
  lastname: string;
  remaining_time: number;
}

interface IResponse {
  data: IDeliverResponse[];
  current_page: number;
  total: number;
  from: number;
}

interface IDeliveryData {
  index: number;
  id: number;
  horary: string;
  confirmed: number;
  created_at: string;
  enrollment: string;
  name: string;
  lastname: string;
  remaining_time: number;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

const Table: React.FC = () => {
  const params = useParams<IParams>();
  const { permission } = usePermission();
  const [search, setSearch] = useState('');
  const [deliveries, setDelivery] = useState<IDeliveryData[]>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const { loadTurns, setLoadTurns, setLoadStudents } = useDelivery();
  const [documentVisible, setDocumentVisible] = useState(false);

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>(`/deliveries/${params.delivery_id}/turns`, {
        params: {
          page: pageSelected,
          search,
          column,
          order,
          perPage,
          confirm: 0,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((deliverData, index) => ({
          index: response.data.from + index,
          id: deliverData.id,
          horary: deliverData.horary,
          confirmed: deliverData.confirmed,
          created_at: deliverData.created_at,
          enrollment: deliverData.enrollment,
          name: deliverData.name,
          lastname: deliverData.lastname,
          remaining_time: deliverData.remaining_time,
        }));

        setDelivery(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoadTurns(false);
      });
  }, [
    pageSelected,
    search,
    column,
    order,
    perPage,
    params.delivery_id,
    setLoadTurns,
  ]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable, loadTurns]);

  const handleConfirmStudent = useCallback(
    async (turnData: IDeliveryData) => {
      setLoading(true);
      await api.patch(`/deliveries/${params.delivery_id}/turns/${turnData.id}`);
      loadDatatable();
      setLoadStudents(true);
    },
    [params.delivery_id, loadDatatable, setLoadStudents]
  );

  const handleExcelButtonClick = useCallback(async () => {
    try {
      setLoading(true);
      api
        .get(
          `${process.env.REACT_APP_API_URL}/deliveries/${params.delivery_id}/turns/excel`,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const urlData = generateUrlBlob(response);
          const link = document.createElement('a');
          link.href = urlData;
          link.download = `turns.xlsx`;
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(urlData);
            link.remove();
          }, 100);
        })
        .catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'Error al descargar el archivo',
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {
      //
    }
  }, [params.delivery_id]);

  const handlePdfButtonClick = useCallback(async () => {
    setDocumentVisible(true);
  }, []);

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
        width: 10,
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: (cell: number, row) => {
          return (
            <div className="w-100">
              {permission >= 2 && row.remaining_time >= 0 && (
                <button
                  type="button"
                  onClick={() => handleConfirmStudent(row)}
                  className="bg-transparent border-0"
                >
                  <FiCheckCircle
                    size={24}
                    color="#0fba00"
                    title="Cambiar datos"
                  />
                </button>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'horary',
        text: 'Horario',
        sort: true,
      },
      {
        dataField: 'enrollment',
        text: 'Numero de alumno',
        sort: true,
      },
      {
        dataField: 'lastname',
        text: 'Alumno',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <div>
              {row.lastname}, <span className="fw-bold">{row.name}</span>
            </div>
          );
        },
      },
      {
        dataField: 'created_at',
        text: 'Fecha que sacó turno',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.created_at), 'dd/MM/yyyy HH:mm');
        },
      },
    ];
  }, [handleConfirmStudent, permission]);

  const handleSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setOrder(datatableParams.sortOrder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  return (
    <>
      <div className="py-3">
        <div className="row">
          <div className="col-12 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={deliveries}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onSearch={handleSearch}
              onTableChange={handleChangePage}
              loading={loading}
              defaultSorted={[
                {
                  dataField: 'horary',
                  order: 'asc',
                },
              ]}
              searchable
              showExcelButton
              onExcelButonClick={handleExcelButtonClick}
              onPdfButonClick={handlePdfButtonClick}
            />
          </div>
        </div>
      </div>
      <Loading show={loading} message="Cargando datos..." />
      {documentVisible && (
        <ModalFile
          onHide={() => setDocumentVisible(false)}
          show={documentVisible}
          url={`${process.env.REACT_APP_API_URL}/deliveries/${params.delivery_id}/turns/pdf`}
          header="Turnos"
        />
      )}
    </>
  );
};

export default Table;

import styled from 'styled-components';

interface ILoading {
  active?: boolean;
  fixed?: boolean;
}

export const Loading = styled.div<ILoading>`
  transition-duration: 0.3s;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  span {
    color: #fff !important;
  }
`;

import styled from 'styled-components';

interface ContainerProps {
  color?: string;
}

export const Container = styled.div<ContainerProps>`
  text-align: center;

  span {
    background-color: ${({ color }) => color || 'transparent'} !important;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    font-size: 12px;
    margin-right: 5px;
    font-weight: 500;
  }
`;

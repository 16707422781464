import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Turns from './Turns';
import Students from './Students';
import { DeliveryProvider } from './Context/Index';

const CustomerItems: React.FC = () => {
  return (
    <DeliveryProvider>
      <div className="py-5 px-sm-5">
        <div className="row">
          <div className="col-12 overflow-auto d-flex flex-column justify-content-between">
            <Tabs defaultActiveKey="turns" id="uncontrolled-tab">
              <Tab eventKey="turns" title="Turnos">
                <Turns />
              </Tab>
              <Tab eventKey="students" title="Alumnos">
                <Students />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </DeliveryProvider>
  );
};

export default CustomerItems;

import styled from 'styled-components';

export const Container = styled.div`
  background-color: #ffffff;
  > div {
    min-height: 100vh;
  }

  .position-absolute {
    top: 20px;
    right: 20px;
    min-height: auto;
  }

  .logo {
    width: 150px;
  }
`;

export const Content = styled.div`
  h1 {
    color: #31344e;
  }

  p {
    color: #8692a6;
  }

  a {
    color: #1565d8;
  }

  label {
    color: #696f79;
  }

  .btn-submit {
    color: #ffffff;
    background: #cb9614;
    border-radius: 10px;
  }

  form {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    form {
      width: 75%;
    }
  }

  @media screen and (min-width: 1200px) {
    form {
      width: 60%;
    }
  }

  @media screen and (min-width: 1600px) {
    form {
      width: 45%;
    }
  }

  @media screen and (min-width: 2000px) {
    form {
      width: 35%;
    }
  }
`;

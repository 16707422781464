import React from 'react';

import TableDeliveries from './Partials/Table';

const Deliveries: React.FC = () => {
  return (
    <>
      <TableDeliveries />
    </>
  );
};

export default Deliveries;

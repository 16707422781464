import React, { useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import Input from '~/components/Input';

import { Modal } from './styles';

interface IInscriptionPeriodData {
  id: number;
  year: number;
  period: number;
  inscription_begin: Date;
  inscription_end: Date;
  basket_limit: Date;
}

interface IModalProps {
  show: boolean;
  onHide: () => void;
  periodParams: IInscriptionPeriodData;
  onRefreshTable: (refreshTable: boolean) => void;
}

const FormEdit: React.FC<IModalProps> = ({
  show,
  onHide,
  onRefreshTable,
  periodParams,
}) => {
  const formEnrollmentPeriodRef = useRef<FormHandles>(null);

  const handleSubmitEditCertification = useCallback(
    async (data: IInscriptionPeriodData) => {
      try {
        formEnrollmentPeriodRef.current?.setErrors({});

        const schema = Yup.object().shape({
          year: Yup.string().required('Año necessário'),
          period: Yup.string().required('Cuatrimestre necesario'),
          inscription_begin: Yup.string().required(
            'Inicio inscripción necesario'
          ),
          inscription_end: Yup.string().required(
            'Fin de inscripcion necesario'
          ),
          basket_limit: Yup.string().required('Fecha limite necesaria'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        periodParams.id
          ? await api.put(`enrollment-periods/${periodParams.id}`, data)
          : await api.post(`enrollment-periods `, data);

        onHide();

        if (onRefreshTable) {
          onRefreshTable(true);
        }

        periodParams.id
          ? Toast.fire({
              icon: 'success',
              title: 'Cadastro alterado com sucesso!',
            })
          : Toast.fire({
              icon: 'success',
              title: 'Cadastro efetuado com sucesso!',
            });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formEnrollmentPeriodRef.current?.setErrors(errors);
        } else if (error?.response.data?.message) {
          Swal.fire('Opss...', error?.response.data?.message, 'error');
        } else {
          Swal.fire('Opss...', 'Erro ao guardar los datos', 'error');
        }
      }
    },
    [onHide, onRefreshTable, periodParams.id]
  );

  return (
    <Modal show={show} onHide={onHide} close>
      <Form
        ref={formEnrollmentPeriodRef}
        initialData={periodParams}
        onSubmit={handleSubmitEditCertification}
        className="p-4"
      >
        <Modal.Header className="border-none" closeButton>
          {periodParams.id ? (
            <h4>Editar datos del periodo</h4>
          ) : (
            <h4>Añadir periodo</h4>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <div>
              <label>Año</label>
              <Input name="year" type="number" step="1" min="1" />
            </div>
          </div>
          <div className="row mb-3">
            <div>
              <label>Periodo *</label>
              <Input name="period" type="number" step="1" min="1" max="2" />
            </div>
          </div>

          <div className="row mb-3">
            <div>
              <label>Fecha inicio de inscripción *</label>
              <Input type="date" name="inscription_begin" />
            </div>
          </div>

          <div className="row mb-3">
            <div>
              <label>Fecha término de inscripción *</label>
              <Input type="date" name="inscription_end" />
            </div>
          </div>

          <div className="row mb-3">
            <div>
              <label>Fecha limite para el Bolsón *</label>
              <Input type="date" name="basket_limit" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Cancelar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Añadir
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormEdit;

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IoIosArrowForward, BiLogOutCircle } from 'react-icons/all';

import { IconType } from 'react-icons';
import { usePermission } from '~/hooks/Permission';
import { useAuth } from '~/hooks/Auth';

import {
  Container,
  BackgroundOpacity,
  Nav,
  ButtonOpen,
  MenuButton,
} from './styles';

import logo from '~/assets/logos/logo.svg';
import api from '~/services/api';

interface IMenuResponse {
  id: number;
  order: number;
  name: string;
  url: string;
  icon: string;
  permission: number;
}

interface IMenuData {
  id: number;
  order: number;
  name: string;
  url: string;
  icon: IconType;
  permission: number;
}

interface IRoute {
  id: number;
  order: number;
  name: string;
  url: string;
  icon: string;
  permission: number;
}

const SideNavBar: React.FC = () => {
  const { setPermission } = usePermission();
  const { signOut } = useAuth();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [menuItems, setMenuItems] = useState<IMenuData[]>([]);

  useEffect(() => {
    api
      .get<IMenuResponse[]>('get-access')
      .then((response) => {
        const data = response.data.map((menu) => {
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          const icon = require('react-icons/all');
          return {
            id: menu.id,
            order: menu.order,
            name: menu.name,
            url: menu.url,
            icon: icon[menu.icon] as IconType,
            permission: menu.permission,
          };
        });
        setRoutes(response.data);
        setMenuItems(data);
      })
      .catch(() => {
        signOut();
        localStorage.removeItem('@ASAUAP:token');
        localStorage.removeItem('@ASAUAP:user');
      });
  }, [signOut]);

  useEffect(() => {
    const route = location.pathname.slice(1);

    const routeSelected = routes.find((routeData) =>
      route.match(routeData.url)
    );
    if (routeSelected) {
      setPermission(
        routeSelected.permission,
        routeSelected.name,
        routeSelected.url
      );
    }
  }, [location, routes, setPermission]);

  const handleOpen = useCallback(() => setOpen(true), []);

  const handleClose = useCallback(() => setOpen(false), []);

  const handleClickSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  return (
    <Container show={open}>
      <BackgroundOpacity show={open} onClick={handleClose} />
      <div id="content" className="content">
        <ButtonOpen
          type="button"
          className="d-flex flex-column flex-lg-row justify-content-center align-items-center btn-show ms-auto"
          onClick={!open ? handleOpen : handleClose}
          show={open}
        >
          <IoIosArrowForward
            size={30}
            color="#D0D0D0"
            className="d-none d-lg-block"
          />
          <div className="d-flex flex-column justify-content-center align-items-center d-lg-none">
            <span />
            <span />
            <span />
          </div>
        </ButtonOpen>
        <div>
          <div className="d-flex flex-column justify-content-between pt-2 pb-0 py-lg-4 px-4">
            <div className="d-flex justify-content-between w-100 group-language">
              <div className="d-flex align-items-center mb-lg-4 logo">
                <img src={logo} alt="logo" />
                <span className="ms-3">ASA UAP</span>
              </div>
            </div>
            <div className="menu">
              <div>
                <Nav show={open}>
                  <div className="d-none d-lg-block mb-1 me-n5" />
                  <div className="navbar d-flex flex-column align-items-center align-items-lg-start justify-content-center justify-content-lg-between p-lg-0">
                    {menuItems.map((menu) => (
                      <div key={menu.id} className="my-2">
                        <MenuButton
                          to={`${process.env.PUBLIC_URL}/${menu.url}`}
                          className="d-flex align-items-center justify-content-between px-3"
                          activeClassName="active"
                          active={open}
                          selected={false}
                        >
                          <div className="d-flex align-items-center">
                            <menu.icon size={24} />
                            <span className="ms-3 text-start">{menu.name}</span>
                          </div>
                        </MenuButton>
                      </div>
                    ))}
                  </div>
                  <div className="d-fles flex-column align-items-center align-items-lg-start justify-content-center justify-content-lg-between p-lg-0 mt-5 logout">
                    <button
                      type="button"
                      className="d-flex align-items-center border-0 bg-transparent ms-auto w-100"
                      onClick={handleClickSignOut}
                    >
                      <BiLogOutCircle
                        size={25}
                        color="#FF033F"
                        className="me-1"
                      />
                      <span>Log out</span>
                    </button>
                  </div>
                </Nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SideNavBar;

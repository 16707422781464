import React, { SyntheticEvent, useCallback } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import BootstrapTable, {
  ColumnDescription,
  SortOrder,
  RowSelectionType,
} from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { BsFilter } from 'react-icons/bs';

import { TiPlus } from 'react-icons/ti';
import { HiOutlineSearch } from 'react-icons/hi';

import { FaCloudUploadAlt, FaRegFileExcel } from 'react-icons/fa';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { Container, Input, Loading } from './styles';

interface IData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface IParamsOnTableChange {
  page: number;
  searchText?: string;
  sizePerPage: number;
  sortField?: string | null;
  sortOrder?: string;
}

interface ICertificationPlanningData {
  index: number;
  id: number;
  code: number;
  operatingUnit: string;
  client: string;
  group: string;
  model: string;
  equipment: string;
  certification: string;
  expiration: string;
  expiration_days: number;
  mainEquipment: string;
  planning_date: string;
  validationDate: string;
  status: string;
  in_use: number | string;
}
interface ISelectRowProps {
  mode: RowSelectionType;
  clickToSelect: boolean;
  onSelect: (
    row: ICertificationPlanningData,
    isSelected: boolean,
    rowIndex: number,
    e: SyntheticEvent
  ) => void | boolean;
  onSelectAll: (
    isSelect: boolean,
    rows: ICertificationPlanningData[],
    e: React.SyntheticEvent
  ) => void | number[];
  hideSelectAll: boolean;
  style: {
    backgroundColor: string;
  };
}

interface ITableProps {
  title?: string;
  addButton?: boolean;
  backButton?: boolean;
  dropDownItensMultiple?: boolean;
  uppFileButton?: boolean;
  columns: ColumnDescription[];
  data: IData[];
  searchable?: boolean;
  toData?: number;
  loading?: boolean;
  showAddButton?: boolean;
  showUploadButton?: boolean;
  showExcelButton?: boolean;
  page: number;
  totalSize: number;
  sizePerPage: number;
  showFilterButton?: boolean;
  selectRow?: ISelectRowProps;
  defaultSorted?:
    | [
        {
          dataField: string;
          order: SortOrder;
        }
      ];
  onTableChange?(e: string, params: IParamsOnTableChange): void;
  onChangeOrder?(column: string, order: 'ASC' | 'DESC'): void;
  onAddButonClick?(): void;
  onUploadButonClick?(): void;
  onExcelButonClick?(): void;
  onPdfButonClick?(): void;
  onFilterClick?(): void;
  onRowClicked?(e: IData): void;
  onSearch?(e: string): void;
}

const DataTable: React.FC<ITableProps> = ({
  title,
  addButton,
  backButton,
  dropDownItensMultiple,
  uppFileButton,
  columns,
  data,
  searchable,
  page,
  sizePerPage,
  totalSize,
  selectRow,
  defaultSorted,
  showAddButton,
  showUploadButton,
  showExcelButton,
  onTableChange,
  showFilterButton,
  onFilterClick,
  onAddButonClick,
  onUploadButonClick,
  onExcelButonClick,
  onPdfButonClick,
  onSearch,
}) => {
  const customTotal = (from: number, to: number, size: number) => (
    <span className="react-bootstrap-table-pagination-total ms-3">
      Mostrando
      <strong> {from} </strong> a <strong> {to} </strong> de{' '}
      <strong> {size.toLocaleString('en-US')} </strong> registros
    </span>
  );

  const paginationOptions = {
    page,
    sizePerPage,
    totalSize,
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'Primera',
    prePageText: 'Anterior',
    nextPageText: 'Próxima',
    lastPageText: 'Última',
    nextPageTitle: 'Primera pagina',
    prePageTitle: 'Pagina anterior',
    firstPageTitle: 'Próxima pagina',
    lastPageTitle: 'Ultima pagina',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '25',
        value: 25,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
    ],
  };

  const handleClickFilter = useCallback(() => {
    if (onFilterClick) {
      onFilterClick();
    }
  }, [onFilterClick]);

  const handleClickAddButton = useCallback(() => {
    if (onAddButonClick) {
      onAddButonClick();
    }
  }, [onAddButonClick]);

  const handleClickUploadButton = useCallback(() => {
    if (onUploadButonClick) {
      onUploadButonClick();
    }
  }, [onUploadButonClick]);

  const handleClickExcelButton = useCallback(() => {
    if (onExcelButonClick) {
      onExcelButonClick();
    }
  }, [onExcelButonClick]);

  const handleClickPdfButton = useCallback(() => {
    if (onPdfButonClick) {
      onPdfButonClick();
    }
  }, [onPdfButonClick]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      if (onSearch) {
        onSearch(value);
      }
    },
    [onSearch]
  );

  return (
    <>
      <Container>
        {(title ||
          addButton ||
          backButton ||
          uppFileButton ||
          dropDownItensMultiple ||
          searchable ||
          showFilterButton ||
          showAddButton) && (
          <div className="d-flex flex-wrap justify-content-start align-items-center mb-4 mb-sm-5">
            <div className="w-100 w-sm-auto d-flex align-items-center">
              {searchable && (
                <Input className="d-flex me-3" onChange={handleChange}>
                  <input type="text" placeholder="Busque en" />
                  <HiOutlineSearch size={24} color="#2A8BFD" />
                </Input>
              )}
              {showFilterButton && (
                <button
                  type="button"
                  className="btn-action border-0"
                  onClick={handleClickFilter}
                >
                  <BsFilter size={24} color="#414141" />
                </button>
              )}
              {showAddButton && (
                <button
                  type="button"
                  className="btn-action border-0 ms-3"
                  onClick={handleClickAddButton}
                >
                  <TiPlus size={24} color="#CB9614" />
                </button>
              )}
              {showUploadButton && (
                <button
                  type="button"
                  className="btn-action border-0 ms-3"
                  onClick={handleClickUploadButton}
                >
                  <FaCloudUploadAlt size={24} color="#f4ce6c" />
                </button>
              )}
              {showExcelButton && (
                <button
                  type="button"
                  className="btn-action border-0 ms-3"
                  onClick={handleClickExcelButton}
                >
                  <FaRegFileExcel size={24} color="#24b702" />
                </button>
              )}
              {onPdfButonClick && (
                <button
                  type="button"
                  className="btn-action border-0 ms-3"
                  onClick={handleClickPdfButton}
                >
                  <AiOutlineFilePdf size={24} color="#f60707" />
                </button>
              )}
            </div>
          </div>
        )}
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          remote
          bootstrap4
          // striped
          hover
          selectRow={selectRow}
          bordered={false}
          wrapperClasses="table-responsive"
          pagination={paginationFactory(paginationOptions)}
          onTableChange={onTableChange}
          defaultSorted={defaultSorted}
          noDataIndication="Ningún dato encontrado"
        />
        <Loading active={false}>
          <div className="loading-box d-flex flex-column align-items-center justify-content-center">
            <div className="spinner-border text-light" role="status" />
            <span className="d-block fw-bold">Carregando...</span>
          </div>
        </Loading>
      </Container>
    </>
  );
};

export default DataTable;

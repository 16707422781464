import React, { useCallback, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import animation from '~/assets/animations/login.json';
import { useAuth } from '~/hooks/Auth';

import { Container, Content } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo.svg';
import getValidationErros from '~/utils/getValidationsErrors';
import Loading from '~/components/Loading';

interface IFormData {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Correo no válido')
            .required('Correo requerido'),
          password: Yup.string().required('Contraseña requerida'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Erro al ascender al sistema', 'error');
        }
      } finally {
        setLoading(false);
      }
    },
    [signIn]
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container className="container-fluid">
      <div className="row">
        <div className="col-lg-5 d-none d-lg-flex flex-column justify-content-center align-items-center px-0">
          <Lottie options={defaultOptions} height={600} width={600} />
        </div>
        <Content className="col-lg-7 d-flex flex-column justify-content-center align-items-center">
          <div className="row w-100">
            <div className="d-flex mt-2 mb-5 flex-row-reverse justify-content-center">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </div>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="px-3 px-sm-4 px-md-5 px-lg-0"
          >
            <h1 className="fw-bold mb-3">Entrar</h1>
            <p className="mb-5">Bienvenido al sistema de ASA</p>
            <div className="mb-4">
              <label htmlFor="email" className="mb-2 fw-medium">
                Correo
              </label>
              <Input
                type="email"
                placeholder="email@example.com"
                name="email"
              />
            </div>
            <div className="mb-2">
              <label htmlFor="email" className="mb-2 fw-medium">
                Contraseña
              </label>
              <Input type="password" placeholder="******" name="password" />
            </div>
            <div className="mb-3">
              <button
                type="submit"
                className="btn btn-submit mb-2 w-100 fw-bold py-3"
              >
                Entrar
              </button>
            </div>
            <div className="text-center mt-2">
              <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>
                Olvidé mi contraseña
              </Link>
            </div>
          </Form>
        </Content>
      </div>
      <Loading show={loading} message="Cargando datos..." />
    </Container>
  );
};

export default Login;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';

import { BiEditAlt } from 'react-icons/bi';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import { usePermission } from '~/hooks/Permission';

import api from '~/services/api';

import DataTable from '~/components/DataTable';

import FormEdit from '../Formulario';
import Filter from '../Filters';
import ColumnHighlight from '~/components/ColumnHighlight';
import Loading from '~/components/Loading';

interface IDeliverResponse {
  id: number;
  period: string;
  date: string;
  basic_basket: number;
  cereal: number;
  cookie: number;
  colporteur: number;
  turns_number: number;
  begin_turn: string;
  end_turn: string;
  turns_count: number;
  students_count: number;
}

interface IResponse {
  data: IDeliverResponse[];
  current_page: number;
  total: number;
  from: number;
}

interface IDeliveryData {
  index: number;
  id: number;
  period: string;
  date: string;
  basic_basket: number;
  cereal: number;
  cookie: number;
  colporteur: number;
  turns_number: number;
  begin_turn: string;
  end_turn: string;
  turns_count: number;
  students_count: number;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

interface IFilterData {
  name: string;
  direction: string;
}

const Table: React.FC = () => {
  const { permission } = usePermission();
  const [deliveries, setDelivery] = useState<IDeliveryData[]>([]);
  const [deliverySelected, setDeliverySelected] = useState<IDeliveryData>(
    {} as IDeliveryData
  );
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [showEdit, setShowEdit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>('/deliveries', {
        params: {
          page: pageSelected,
          column,
          order,
          perPage,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((deliverData, index) => ({
          index: response.data.from + index,
          id: deliverData.id,
          period: deliverData.period,
          date: deliverData.date,
          basic_basket: deliverData.basic_basket,
          cereal: deliverData.cereal,
          cookie: deliverData.cookie,
          colporteur: deliverData.colporteur,
          turns_number: deliverData.turns_number,
          begin_turn: deliverData.begin_turn,
          end_turn: deliverData.end_turn,
          turns_count: deliverData.turns_count,
          students_count: deliverData.students_count,
        }));

        setDelivery(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSelected, column, order, perPage]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable]);

  const handleAddDelivery = useCallback(() => {
    setDeliverySelected({} as IDeliveryData);
    setShowEdit(true);
  }, []);

  const handleEditDeliver = useCallback(async (deliverData: IDeliveryData) => {
    setDeliverySelected(deliverData);
    setShowEdit(true);
  }, []);

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
        width: 10,
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: (cell: number, row) => {
          return (
            <div className="w-100">
              <Link
                to={`${process.env.PUBLIC_URL}/deliveries/${row.id}`}
                className="border-0"
              >
                <AiFillEye size={24} color="#0fba00" />
              </Link>
              {permission >= 2 && (
                <button
                  type="button"
                  onClick={() => handleEditDeliver(row)}
                  className="bg-transparent border-0"
                >
                  <BiEditAlt size={24} color="#3624f5" title="Cambiar datos" />
                </button>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'period',
        text: 'Periodo',
      },
      {
        dataField: 'date',
        text: 'Fecha',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <div className="d-flex flex-column">
              <div>{format(parseISO(row.date), 'dd/MM/yyyy HH:mm')}</div>
              <div className="d-flex flex-row">
                {row.basic_basket ? (
                  <ColumnHighlight value="Bolsón" color="red" />
                ) : (
                  ''
                )}
                {row.cookie ? (
                  <ColumnHighlight value="Galletas" color="yellow" />
                ) : (
                  ''
                )}
                {row.cereal ? (
                  <ColumnHighlight value="Cereales" color="green" />
                ) : (
                  ''
                )}
                {row.colporteur ? (
                  <ColumnHighlight value="Colportor" color="orange" />
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'turns_number',
        text: 'Cantidad de turnos',
        sort: true,
      },
      {
        dataField: 'begin_turn',
        text: 'Inicio de los turnos',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.begin_turn), 'dd/MM/yyyy HH:mm');
        },
      },
      {
        dataField: 'end_turn',
        text: 'Término de los turnos',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.end_turn), 'dd/MM/yyyy HH:mm');
        },
      },
      {
        dataField: 'turns_count',
        text: 'Turnos / Alumnos',
        sort: false,
        formatter: (cell: number, row) => {
          return `${row.turns_count} / ${row.students_count}`;
        },
      },
    ];
  }, [handleEditDeliver, permission]);

  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setOrder(datatableParams.sortOrder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  const handleClearFilter = useCallback(() => {
    setPageSelected(1);
    setShowFilter(false);
    loadDatatable();
  }, [loadDatatable]);

  const handleSubmitFilters = useCallback(() => {
    setPageSelected(1);
    setShowFilter(false);
  }, []);

  const handleLoadParamFilter = useCallback(
    (data: IFilterData) => {
      if (data.name.length > 0) {
        handleSubmitFilters();
      }
    },
    [handleSubmitFilters]
  );

  const handleLoadParamEdit = useCallback(
    (refreshTable: boolean) => {
      if (refreshTable) {
        loadDatatable();
        setShowEdit(false);
      }
    },
    [loadDatatable]
  );

  return (
    <>
      <div className="py-5 px-sm-5">
        <div className="row">
          <div className="col-12 px-4 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={deliveries}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onTableChange={handleChangePage}
              onAddButonClick={handleAddDelivery}
              loading={loading}
              defaultSorted={[
                {
                  dataField: 'date',
                  order: 'desc',
                },
              ]}
              showAddButton
            />
          </div>
        </div>
      </div>

      <FormEdit
        show={showEdit}
        onHide={() => setShowEdit(false)}
        deliverParams={deliverySelected}
        onRefreshTable={handleLoadParamEdit}
      />
      <Filter
        show={showFilter}
        onHide={() => setShowFilter(false)}
        onLoadFilterParam={handleLoadParamFilter}
        onFilterClean={() => handleClearFilter()}
      />
      <Loading show={loading} message="Cargando datos..." />
    </>
  );
};

export default Table;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { format, parseISO } from 'date-fns';

import { BiEditAlt } from 'react-icons/bi';
import { usePermission } from '~/hooks/Permission';
import api from '~/services/api';
import DataTable from '~/components/DataTable';

import FormEdit from '../Formulario';
import Loading from '~/components/Loading';

interface IEnrollmentPeriodResponse {
  id: number;
  year: number;
  period: number;
  inscription_begin: Date;
  inscription_end: Date;
  basket_limit: Date;
}

interface IResponse {
  data: IEnrollmentPeriodResponse[];
  current_page: number;
  total: number;
  from: number;
}

export interface IEnrollmentPeriodData {
  index: number;
  id: number;
  year: number;
  period: number;
  inscription_begin: Date;
  inscription_end: Date;
  basket_limit: Date;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

const Table: React.FC = () => {
  const { permission } = usePermission();
  const [enrollmentPeriods, setEnrollmentPeriods] = useState<
    IEnrollmentPeriodData[]
  >([]);
  const [enrollmentPeriodSelected, setEnrollmentPeriodSelected] =
    useState<IEnrollmentPeriodData>({} as IEnrollmentPeriodData);
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });

  const [column, setColumn] = useState('');
  const [order, setOrder] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>('/enrollment-periods', {
        params: {
          page: pageSelected,
          column,
          order,
          perPage,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((inscriptionData, index) => ({
          index: response.data.from + index,
          id: inscriptionData.id,
          year: inscriptionData.year,
          period: inscriptionData.period,
          inscription_begin: inscriptionData.inscription_begin,
          inscription_end: inscriptionData.inscription_end,
          basket_limit: inscriptionData.basket_limit,
        }));

        setEnrollmentPeriods(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSelected, column, order, perPage]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable]);

  const handleAddEnrollmentPeriod = useCallback(() => {
    setEnrollmentPeriodSelected({} as IEnrollmentPeriodData);
    setShowEdit(true);
  }, []);

  const handleEditStudent = useCallback(
    async (periodData: IEnrollmentPeriodData) => {
      setEnrollmentPeriodSelected(periodData);
      setShowEdit(true);
    },
    []
  );

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
        width: 10,
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: (cell: number, row) => {
          return (
            <div className="w-100">
              {permission >= 2 && (
                <button
                  type="button"
                  onClick={() => handleEditStudent(row)}
                  className="bg-transparent border-0"
                >
                  <BiEditAlt size={24} color="#3624f5" title="Cambiar datos" />
                </button>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'year',
        text: 'Periodo',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <span>
              {row.year}/{row.period}
            </span>
          );
        },
      },
      {
        dataField: 'inscription_begin',
        text: 'Fecha inicio de inscripción',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.inscription_begin), 'dd/MM/yyyy');
        },
      },
      {
        dataField: 'inscription_end',
        text: 'Fecha término de inscripción',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.inscription_end), 'dd/MM/yyyy');
        },
      },
      {
        dataField: 'basket_limit',
        text: 'Fecha limite para el Bolsón',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.basket_limit), 'dd/MM/yyyy');
        },
      },
    ];
  }, [handleEditStudent, permission]);

  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setOrder(datatableParams.sortOrder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  const handleLoadParamEdit = useCallback(
    (refreshTable: boolean) => {
      if (refreshTable) {
        loadDatatable();
        setShowEdit(false);
      }
    },
    [loadDatatable]
  );

  return (
    <>
      <div className="py-5 px-sm-5">
        <div className="row">
          <div className="col-12 px-4 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={enrollmentPeriods}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onTableChange={handleChangePage}
              onAddButonClick={handleAddEnrollmentPeriod}
              defaultSorted={[
                {
                  dataField: 'year',
                  order: 'desc',
                },
              ]}
              showAddButton
            />
          </div>
        </div>
      </div>
      <FormEdit
        show={showEdit}
        onHide={() => setShowEdit(false)}
        periodParams={enrollmentPeriodSelected}
        onRefreshTable={handleLoadParamEdit}
      />
      <Loading show={loading} message="Cargando datos..." />
    </>
  );
};

export default Table;

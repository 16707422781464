import React, { useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MdHelpOutline } from 'react-icons/md';

import { useAuth } from '~/hooks/Auth';
import { usePermission } from '~/hooks/Permission';

import { Container, Header, Content, Welcome } from './styles';
import SideNavBar from '../SideNavBar';

const NavBar: React.FC = ({ children }) => {
  const { module } = usePermission();
  const { url } = usePermission();
  const { user } = useAuth();

  const name = useMemo(() => {
    return user.name.split(' ')[0];
  }, [user.name]);

  return (
    <Container className="d-flex flex-column flex-lg-row">
      <SideNavBar />
      <div className="body-content">
        <Header className="px-sm-5 py-lg-2 mt-lg-3 mb-4">
          <div className="row">
            <div className="col-12">
              <Content className="py-4 px-2 py-lg-0 px-lg-5">
                <div className="row justify-content-between align-items-center px-3 h-100">
                  <div className="col-lg-6">
                    <Link to={`/${url}`}>
                      <p className="company-name mb-0">{module}</p>
                    </Link>
                  </div>
                  <div className="col-lg-6">
                    <Welcome>
                      <div className="d-flex align-items-center justify-content-between justify-content-lg-end">
                        <div className="d-flex align-items-start align-items-lg-center mt-3 mb-lg-3">
                          <p className="mb-0">
                            Hola, <b>{name}</b>
                          </p>
                        </div>
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/`}
                          className="d-flex align-items-end align-items-lg-center mt-3 mb-lg-3"
                          activeClassName="active"
                        >
                          <MdHelpOutline size={24} color="#41A7FE" />
                        </NavLink>
                      </div>
                    </Welcome>
                  </div>
                </div>
              </Content>
            </div>
          </div>
        </Header>
        {children}
      </div>
    </Container>
  );
};

export default NavBar;

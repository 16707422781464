import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';

import Input from '~/components/Input';
import Select from '~/components/Select';
import { Modal } from './styles';

interface IFilterData {
  name: string;
  lastname: string;
  document: number;
  enrollment: string;
}

interface IFiltersProps {
  show: boolean;
  onHide: () => void;
  onLoadFilterParam: ({ name, lastname }: IFilterData) => void;
  onFilterClean: (clear: boolean) => void;
}

const Filters: React.FC<IFiltersProps> = ({
  show,
  onHide,
  onLoadFilterParam,
  onFilterClean,
}) => {
  const formFilterRef = useRef<FormHandles>(null);

  const handleSendFilterParam = useCallback(
    (data: IFilterData) => {
      try {
        formFilterRef.current?.setErrors({});
        onLoadFilterParam(data);
      } catch (error) {
        Swal.fire('Ops...', 'Erro al buscar los datos', 'error');
      }
    },
    [onLoadFilterParam]
  );

  const handleClearFilter = useCallback(() => {
    if (formFilterRef.current) {
      formFilterRef.current.reset();
    }
    if (onFilterClean) {
      onFilterClean(true);
    }
  }, [onFilterClean]);

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form ref={formFilterRef} onSubmit={handleSendFilterParam}>
        <Modal.Header className="border-none" closeButton>
          <h4>Filtros</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label>Alumno</label>
              <Input name="student" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Matriculado</label>
              <Select
                name="registered"
                placeholder="Selecione"
                options={[
                  { id: 0, value: 'No', selected: false },
                  { id: 1, value: 'Sí', selected: false },
                ]}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Externo</label>
              <Select
                name="external"
                placeholder="Selecione"
                options={[
                  { id: 0, value: 'No', selected: false },
                  { id: 1, value: 'Sí', selected: false },
                ]}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Evaluado</label>
              <Select
                name="confirmed"
                placeholder="Selecione"
                options={[
                  {
                    id: 0,
                    value: 'Aprobado para galletas y cereales',
                    selected: false,
                  },
                  { id: 1, value: 'Aprobado para Bolsón', selected: false },
                  { id: -1, value: 'No evaluado', selected: false },
                ]}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Listo para el Bolsón</label>
              <Select
                name="ready"
                placeholder="Selecione"
                options={[
                  { id: 0, value: 'No', selected: false },
                  { id: 1, value: 'Sí', selected: false },
                ]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group col-12 my-2 d-flex justify-content-end">
            <button
              type="button"
              onClick={handleClearFilter}
              className="btn btn-secondary me-2"
            >
              Limpiar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Buscar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Filters;

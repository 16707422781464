import React from 'react';

import TableInscriptions from './Partials/Table';

const Inscriptions: React.FC = () => {
  return (
    <>
      <TableInscriptions />
    </>
  );
};

export default Inscriptions;

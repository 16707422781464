import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { BiEditAlt } from 'react-icons/bi';

import { usePermission } from '~/hooks/Permission';
import api from '~/services/api';

import DataTable from '~/components/DataTable';

import FormEdit from '../Formulario';
import Filter from '../Filters';
import ColumnHighlight from '~/components/ColumnHighlight';
import Loading from '~/components/Loading';
import FormUpload from '../FormUpload';

interface IStudentsResponse {
  id: number;
  enrollment: string;
  name: string;
  lastname: string;
  career: string;
  doc_type_id: number;
  document: string;
  registered: string;
  external: string;
  nacionality: string;
  road: string;
  number: string;
  complement: string;
  phone: string;
  email: string;
  colporteur: string;
  colporteur_leader: string;
}

interface IResponse {
  data: IStudentsResponse[];
  current_page: number;
  total: number;
  from: number;
}

interface IStudentsData {
  index: number;
  id: number;
  enrollment: string;
  name: string;
  lastname: string;
  career: string;
  doc_type_id: number;
  document: string;
  registered: string;
  external: string;
  nacionality: string;
  road: string;
  number: string;
  complement: string;
  phone: string;
  email: string;
  colporteur: string;
  colporteur_leader: string;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

interface IFilterData {
  name: string;
  lastname: string;
  document: number;
  enrollment: string;
}

const Table: React.FC = () => {
  const { permission } = usePermission();
  const [search, setSearch] = useState('');
  const [students, setstudents] = useState<IStudentsData[]>([]);
  const [studentSelected, setStudentSelected] = useState<IStudentsData>(
    {} as IStudentsData
  );
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [showEdit, setShowEdit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>('students', {
        params: {
          page: pageSelected,
          search,
          column,
          order,
          perPage,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((studentData, index) => ({
          index: response.data.from + index,
          id: studentData.id,
          enrollment: studentData.enrollment,
          name: studentData.name,
          lastname: studentData.lastname,
          career: studentData.career,
          doc_type_id: studentData.doc_type_id,
          document: studentData.document,
          registered: studentData.registered,
          external: studentData.external,
          nacionality: studentData.nacionality,
          road: studentData.road,
          number: studentData.number,
          complement: studentData.complement,
          phone: studentData.phone,
          email: studentData.email,
          colporteur: studentData.colporteur,
          colporteur_leader: studentData.colporteur_leader,
        }));

        setstudents(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSelected, search, column, order, perPage]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable]);

  const handleSearch = useCallback(
    (value) => {
      setSearch(value);
      setPageSelected(pageSelected);
    },
    [pageSelected]
  );

  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setOrder(datatableParams.sortOrder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  const handleAddStudent = useCallback(() => {
    setStudentSelected({} as IStudentsData);
    setShowEdit(true);
  }, []);

  const handleUploadFile = useCallback(() => {
    setShowUpload(true);
  }, []);

  const handleClearFilter = useCallback(() => {
    setPageSelected(1);
    setShowFilter(false);
    loadDatatable();
  }, [loadDatatable]);

  const handleSubmitFilters = useCallback(() => {
    setPageSelected(1);
    setShowFilter(false);
  }, []);

  const handleLoadParamFilter = useCallback(
    (data: IFilterData) => {
      if (data.name.length > 0) {
        handleSubmitFilters();
      }
    },
    [handleSubmitFilters]
  );

  const handleLoadParamEdit = useCallback(
    (refreshTable: boolean) => {
      if (refreshTable) {
        loadDatatable();
        setShowEdit(false);
      }
    },
    [loadDatatable]
  );

  const handleEditStudent = useCallback(async (studentData: IStudentsData) => {
    setStudentSelected(studentData);
    setShowEdit(true);
  }, []);

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: (cell: number, row) => {
          return (
            <div className="w-100">
              {permission >= 2 && (
                <button
                  type="button"
                  onClick={() => handleEditStudent(row)}
                  className="bg-transparent border-0"
                >
                  <BiEditAlt size={24} color="#3624f5" title="Cambiar datos" />
                </button>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'lastname',
        text: 'Alumno',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <div className="d-flex flex-column">
              <div>
                {row.lastname}, <span className="fw-bold">{row.name}</span>
              </div>
              <div className="d-flex flex-row">
                <ColumnHighlight value={row.enrollment} color="purple" />
                {row.registered ? (
                  <ColumnHighlight value="Matriculado" color="green" />
                ) : (
                  ''
                )}
                {row.external ? (
                  <ColumnHighlight value="Externo" color="blue" />
                ) : (
                  ''
                )}
                {row.colporteur ? (
                  <ColumnHighlight value="Colportor" color="orange" />
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'career',
        text: 'Carrera',
        sort: true,
      },
      {
        dataField: 'road',
        text: 'Dirrecion',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <span>
              {row.road && (
                <>
                  {row.road}, {row.number} {row.complement}
                </>
              )}
            </span>
          );
        },
      },
      {
        dataField: 'phone',
        text: 'Teléfono',
        sort: true,
      },
      {
        dataField: 'email',
        text: 'Correo',
        sort: true,
      },
    ];
  }, [handleEditStudent, permission]);

  return (
    <>
      <div className="py-5 px-sm-5">
        <div className="row">
          <div className="col-12 px-4 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={students}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onFilterClick={() => setShowFilter(true)}
              onSearch={handleSearch}
              onTableChange={handleChangePage}
              onAddButonClick={handleAddStudent}
              loading={loading}
              defaultSorted={[
                {
                  dataField: 'lastname',
                  order: 'asc',
                },
              ]}
              searchable
              showFilterButton
              showAddButton
              showUploadButton
              onUploadButonClick={handleUploadFile}
            />
          </div>
        </div>
      </div>
      <FormEdit
        show={showEdit}
        onHide={() => setShowEdit(false)}
        studentParams={studentSelected}
        onRefreshTable={handleLoadParamEdit}
      />
      <Filter
        show={showFilter}
        onHide={() => setShowFilter(false)}
        onLoadFilterParam={handleLoadParamFilter}
        onFilterClean={() => handleClearFilter()}
      />
      <FormUpload
        show={showUpload}
        onHide={() => setShowUpload(false)}
        onRefreshTable={handleLoadParamEdit}
      />
      <Loading show={loading} message="Cargando datos..." />
    </>
  );
};

export default Table;

import React from 'react';

import TableStudents from './Partials/Table';

const Students: React.FC = () => {
  return (
    <>
      <TableStudents />
    </>
  );
};

export default Students;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavBar from '~/components/NavBar';

import api from '~/services/api';
import { useAuth } from '~/hooks/Auth';

const DefaultLayout: React.FC = ({ children }) => {
  const { signOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    api.get('check-login').catch(() => {
      signOut();
    });
  }, [signOut, location.pathname]);

  return (
    <>
      <NavBar>{children}</NavBar>
    </>
  );
};

export default DefaultLayout;

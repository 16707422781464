import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import Input from '~/components/Input';

import { Modal } from './styles';
import getValidationErros from '~/utils/getValidationsErrors';

interface IFilterData {
  name: string;
  direction: string;
}

interface IFiltersProps {
  show: boolean;
  onHide: () => void;
  onLoadFilterParam: ({ name, direction }: IFilterData) => void;
  onFilterClean: (clear: boolean) => void;
}

const Filters: React.FC<IFiltersProps> = ({
  show,
  onHide,
  onLoadFilterParam,
  onFilterClean,
}) => {
  const formFilterRef = useRef<FormHandles>(null);

  const handleSendFilterParam = useCallback(
    async (data: IFilterData) => {
      try {
        formFilterRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // name: Yup.string().required('Nome necessário'),
          // direction: Yup.string().required('Direção necessária'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (onLoadFilterParam) {
          onLoadFilterParam(data);
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formFilterRef.current?.setErrors(errors);
        } else {
          Swal.fire('Ops...', 'Error al buscar', 'error');
        }
      }
    },
    [onLoadFilterParam]
  );

  const handleClearFilter = useCallback(() => {
    if (formFilterRef.current) {
      formFilterRef.current.reset();
    }
    if (onFilterClean) {
      onFilterClean(true);
    }
  }, [onFilterClean]);

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form
        ref={formFilterRef}
        onSubmit={handleSendFilterParam}
        className="p-2"
      >
        <Modal.Header className="border-none" closeButton>
          <h4>Filtros</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label>Periodo</label>
              <Input type="text" name="period_id" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Fecha </label>
              <Input name="period" type="date" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Fecha</label>
              <Input type="date" name="date" />
            </div>
            <div className="col-lg-6 mb-3">
              <label>Número turno</label>
              <Input type="date" name="turns_number" />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group col-12 my-2 d-flex justify-content-end">
            <button
              type="button"
              onClick={handleClearFilter}
              className="btn btn-secondary me-2"
            >
              Limpiar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Buscar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Filters;

import { FC, useState, useEffect } from 'react';
import { Container } from './styles';

interface ColumnHighlightProps {
  color?: string;
  value: string | null;
}

const colors = {
  red: '#fc5d4a',
  orange: '#ffa500',
  yellow: '#ffc107',
  green: '#4caf50',
  blue: '#41a7fe',
  purple: '#be4bfc',
};

const ColumnHighlight: FC<ColumnHighlightProps> = ({ color, value }) => {
  const [c, setColor] = useState('');

  useEffect(() => {
    if (color) {
      if (color === 'red') setColor(colors.red);
      else if (color === 'orange') setColor(colors.orange);
      else if (color === 'yellow') setColor(colors.yellow);
      else if (color === 'green') setColor(colors.green);
      else if (color === 'blue') setColor(colors.blue);
      else if (color === 'purple') setColor(colors.purple);
    }
  }, [color]);

  return (
    <div>
      {value && (
        <Container color={c}>
          <span className="px-3 py-1">{value}</span>
        </Container>
      )}
    </div>
  );
};

export default ColumnHighlight;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Swal from 'sweetalert2';
import Container from '~/components/Container';
import Select, { IOption } from '~/components/Select';
import api from '~/services/api';

import { IFilters } from '../..';
import { IEnrollmentPeriodData } from '~/pages/EnrollmentPeriods/Partials/Table';
import { FormFilter } from './styles';

interface IProps {
  onChange: (filters: IFilters) => void;
}

const Filters: React.FC<IProps> = ({ onChange }) => {
  const formFilterRef = useRef<FormHandles>(null);
  const [periods, setPeriods] = useState<IOption[]>([]);

  useEffect(() => {
    async function loadPeriods() {
      api
        .get<IEnrollmentPeriodData[]>(`/enrollment-periods`, {
          params: {
            column: 'year',
            order: 'desc',
          },
        })
        .then(async (response) => {
          const data = response.data.map((inscriptionData) => ({
            id: inscriptionData.id,
            value: `${String(inscriptionData.period).padStart(2, '0')}/${
              inscriptionData.year
            }`,
            selected: false,
          }));

          setPeriods(data);
        });
    }
    loadPeriods();
  }, []);

  const handleSendFilterParam = useCallback(
    (data: IFilters) => {
      try {
        formFilterRef.current?.setErrors({});
        onChange(data);
      } catch (error) {
        Swal.fire('Ops...', 'Erro al buscar los datos', 'error');
      }
    },
    [onChange]
  );

  return (
    <Container>
      <h4>Filtros</h4>
      <FormFilter>
        <Form ref={formFilterRef} onSubmit={handleSendFilterParam}>
          <div className="row">
            <div className="col-lg-6 mb-3">
              <label>Período</label>
              <Select
                name="period_id"
                placeholder="Selecione"
                options={periods}
              />
            </div>
            <div className="buttons-group col-6 my-2 d-flex justify-content-start align-items-center">
              <button type="submit" className="btn-submit ms-2">
                Buscar
              </button>
            </div>
          </div>
        </Form>
      </FormFilter>
    </Container>
  );
};

export default Filters;

import React, { useEffect, useState } from 'react';
import api from '~/services/api';

import Enrolleds from './Partials/Charts/Enrolleds';
import EnrolledsBasketBasic from './Partials/Charts/EnrolledsBasketBasic';
import EnrolledsByDay from './Partials/Charts/EnrolledsByDay';
import Filters from './Partials/Filters';

interface IEnrolledsResponse {
  enrolleds: number;
  enabledBasketBasic: number;
  notEnabledBasketBasic: number;
  notEvaluated: number;
}

interface IEnrolledsBasketBasicResponse {
  enabledBasicBasket: number;
  notEnabledBasicBasket: number;
}

interface IEnrolledsByDayResponse {
  quantity: number;
  created_at: string;
}

interface IEnrolledsByDay {
  series: Array<number>;
  categories: Array<string>;
}

export interface IFilters {
  period_id: number;
}

const Dashboard: React.FC = () => {
  const [filters, setFilters] = useState<IFilters>({} as IFilters);
  const [enrolleds, setEnrolleds] = useState<IEnrolledsResponse>({
    enrolleds: 100,
    enabledBasketBasic: 100,
    notEnabledBasketBasic: 100,
    notEvaluated: 100,
  });
  const [enrolledsBasketBasic, setEnrolledsBasketBasic] =
    useState<IEnrolledsBasketBasicResponse>({
      enabledBasicBasket: 1,
      notEnabledBasicBasket: 1,
    });
  const [enrolledsByDay, setEnrolledsByDay] = useState<IEnrolledsByDay>(
    {} as IEnrolledsByDay
  );

  useEffect(() => {
    api
      .get<IEnrolledsResponse>(`/dashboard/enrolleds`, {
        params: filters,
      })
      .then(async (response) => {
        setEnrolleds(response.data);
      });
    api
      .get<IEnrolledsBasketBasicResponse>(`/dashboard/enrolleds-basket-basic`, {
        params: filters,
      })
      .then(async (response) => {
        setEnrolledsBasketBasic(response.data);
      });
    api
      .get<IEnrolledsByDayResponse[]>(`/dashboard/enrolleds-by-day`, {
        params: filters,
      })
      .then(async (response) => {
        const dataResponse: IEnrolledsByDay = {
          series: [],
          categories: [],
        };
        response.data.forEach((item) => {
          dataResponse.series.push(item.quantity);
          dataResponse.categories.push(item.created_at);
        });
        setEnrolledsByDay(dataResponse);
      });
  }, [filters]);

  return (
    <>
      <div className="py-3 px-sm-5">
        <div className="row">
          <div className="col-12 mb-3">
            <Filters
              onChange={(filtersData) => {
                setFilters(filtersData);
              }}
            />
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <Enrolleds enrolleds={enrolleds} />
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <EnrolledsBasketBasic enrolledsBasketBasic={enrolledsBasketBasic} />
          </div>
          <div className="col-12 col-sm-6 mb-3">
            <EnrolledsByDay chartData={enrolledsByDay} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import React, { createContext, useState, useContext, useCallback } from 'react';

interface DeliveryContextData {
  loadTurns: boolean;
  loadStudents: boolean;
  setLoadTurns(value: boolean): void;
  setLoadStudents(value: boolean): void;
}

export const DeliveryContext = createContext<DeliveryContextData>(
  {} as DeliveryContextData
);

export const DeliveryProvider: React.FC = ({ children }) => {
  const [loadTurns, setLoadTurn] = useState(false);
  const [loadStudents, setLoadStudent] = useState(false);

  const setLoadTurns = useCallback((value: boolean) => {
    setLoadTurn(value);
  }, []);

  const setLoadStudents = useCallback((value: boolean) => {
    setLoadStudent(value);
  }, []);

  return (
    <DeliveryContext.Provider
      value={{
        loadTurns,
        loadStudents,
        setLoadTurns,
        setLoadStudents,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};

export function useDelivery(): DeliveryContextData {
  const context = useContext(DeliveryContext);

  if (!context) {
    throw new Error('useDelivery must be used within an DeliveryProvider');
  }

  return context;
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { format, parseISO } from 'date-fns';
import { useParams } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';

import { usePermission } from '~/hooks/Permission';
import api from '~/services/api';

import DataTable from '~/components/DataTable';
import Loading from '~/components/Loading';
import { useDelivery } from '../../../Context/Index';

interface IParams {
  delivery_id: string;
}

interface IDeliverResponse {
  id: number;
  horary: string;
  confirmed: number;
  created_at: string;
  enrollment: string;
  name: string;
  lastname: string;
  remaining_time: number;
}

interface IResponse {
  data: IDeliverResponse[];
  current_page: number;
  total: number;
  from: number;
}

interface IDeliveryData {
  index: number;
  id: number;
  horary: string;
  confirmed: number;
  created_at: string;
  enrollment: string;
  name: string;
  lastname: string;
  remaining_time: number;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

const Table: React.FC = () => {
  const params = useParams<IParams>();
  const { permission } = usePermission();
  const [search, setSearch] = useState('');
  const [deliveries, setDelivery] = useState<IDeliveryData[]>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const { loadStudents, setLoadStudents, setLoadTurns } = useDelivery();

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>(`/deliveries/${params.delivery_id}/turns`, {
        params: {
          page: pageSelected,
          search,
          column,
          order,
          perPage,
          confirm: 1,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((deliverData, index) => ({
          index: response.data.from + index,
          id: deliverData.id,
          horary: deliverData.horary,
          confirmed: deliverData.confirmed,
          created_at: deliverData.created_at,
          enrollment: deliverData.enrollment,
          name: deliverData.name,
          lastname: deliverData.lastname,
          remaining_time: deliverData.remaining_time,
        }));

        setDelivery(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoadStudents(false);
      });
  }, [
    pageSelected,
    search,
    column,
    order,
    perPage,
    params.delivery_id,
    setLoadStudents,
  ]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable, loadStudents]);

  const handleConfirmStudent = useCallback(
    async (turnData: IDeliveryData) => {
      setLoading(true);
      await api.patch(`/deliveries/${params.delivery_id}/turns/${turnData.id}`);
      loadDatatable();
      setLoadTurns(true);
    },
    [params.delivery_id, loadDatatable, setLoadTurns]
  );

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
        width: 10,
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: (cell: number, row) => {
          return (
            <div className="w-100">
              {permission >= 2 && row.remaining_time >= 0 && (
                <button
                  type="button"
                  onClick={() => handleConfirmStudent(row)}
                  className="bg-transparent border-0"
                >
                  <MdOutlineCancel
                    size={24}
                    color="#ff033f"
                    title="Cancelar alumno"
                  />
                </button>
              )}
            </div>
          );
        },
      },
      {
        dataField: 'horary',
        text: 'Horario',
      },
      {
        dataField: 'enrollment',
        text: 'Numero de alumno',
        sort: true,
      },
      {
        dataField: 'lastname',
        text: 'Alumno',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <div>
              {row.lastname}, <span className="fw-bold">{row.name}</span>
            </div>
          );
        },
      },
      {
        dataField: 'created_at',
        text: 'Fecha que sacó turno',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.created_at), 'dd/MM/yyyy HH:mm');
        },
      },
    ];
  }, [handleConfirmStudent, permission]);
  const handleSearch = useCallback(
    (value) => {
      setSearch(value);
      setPageSelected(pageSelected);
    },
    [pageSelected]
  );
  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setOrder(datatableParams.sortOrder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  return (
    <>
      <div className="py-3">
        <div className="row">
          <div className="col-12 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={deliveries}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onSearch={handleSearch}
              onTableChange={handleChangePage}
              loading={loading}
              defaultSorted={[
                {
                  dataField: 'date',
                  order: 'desc',
                },
              ]}
              searchable
            />
          </div>
        </div>
      </div>
      <Loading show={loading} message="Cargando datos..." />
    </>
  );
};

export default Table;

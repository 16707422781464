import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';

import { format, parseISO } from 'date-fns';
import { MdGrading, MdOutlineMarkEmailRead } from 'react-icons/md';
import { FaUserClock } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import api from '~/services/api';

import DataTable from '~/components/DataTable';

import { Dropdown } from './style';
import Quiz from '../Modals/Quiz';
import ColumnHighlight from '~/components/ColumnHighlight';
import Turns from '../Modals/Turns';
import Loading from '~/components/Loading';
import Toast from '~/utils/toast';
import Filters from '../Filters';
import generateUrlBlob from '~/utils/generateUrlBlob';

interface IEnrolledResponse {
  id: number;
  enrollment: string;
  name: string;
  lastname: string;
  registered: string;
  external: string;
  year: number;
  period: number;
  marital_status: boolean;
  coexistence_group: boolean;
  people_house: boolean;
  cookies: boolean;
  cereals: boolean;
  basic_basket: boolean;
  familiar_help: boolean;
  way_familiar_help: boolean;
  another_help: boolean;
  necesity_scale: number;
  necesity_description: string;
  approved: string;
  approved_at: string;
  user_approved: string;
  created_at: string;
}

interface IResponse {
  data: IEnrolledResponse[];
  current_page: number;
  total: number;
  from: number;
}

interface IEnrolledData {
  id: number;
  index: number;
  enrollment: string;
  name: string;
  lastname: string;
  registered: string;
  external: string;
  marital_status: boolean;
  coexistence_group: boolean;
  people_house: boolean;
  period: string;
  cookies: boolean;
  cereals: boolean;
  basic_basket: boolean;
  familiar_help: boolean;
  way_familiar_help: boolean;
  another_help: boolean;
  necesity_scale: number;
  necesity_description: string;
  approved: string;
  approved_at: string;
  user_approved: string;
  created_at: string;
}

interface ITableData {
  currentPage: number;
  totalData: number;
}

interface IFilterData {
  name: string;
  lastname: string;
}

const Table: React.FC = () => {
  const [search, setSearch] = useState('');
  const [enrolleds, setEnrolleds] = useState<IEnrolledData[]>([]);
  const [enrolledSelected, setEnrolledSelected] = useState<IEnrolledData>(
    {} as IEnrolledData
  );
  const [pageSelected, setPageSelected] = useState(1);
  const [tableData, setTableData] = useState<ITableData>({
    currentPage: 1,
    totalData: 0,
  });
  const [column, setColumn] = useState('');
  const [order, setOrder] = useState<'ASC' | 'DESC' | undefined>(undefined);
  const [perPage, setPerPage] = useState(10);
  const [showQuiz, setShowQuiz] = useState(false);
  const [showTurn, setShowTurn] = useState(false);
  const [showMail, setShowMail] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const formFilterRef = useRef<FormHandles>(null);
  const [filters, setFilters] = useState<IFilterData>({} as IFilterData);

  const loadDatatable = useCallback(async () => {
    setLoading(true);
    api
      .get<IResponse>('enrolleds', {
        params: {
          page: pageSelected,
          search,
          column,
          order,
          perPage,
          ...filters,
        },
      })
      .then(async (response) => {
        const data = response.data.data.map((enrolledData, index) => ({
          index: response.data.from + index,
          id: enrolledData.id,
          enrollment: enrolledData.enrollment,
          name: enrolledData.name,
          lastname: enrolledData.lastname,
          registered: enrolledData.registered,
          external: enrolledData.external,
          period: `${enrolledData.year}/${enrolledData.period}`,
          marital_status: enrolledData.marital_status,
          coexistence_group: enrolledData.coexistence_group,
          people_house: enrolledData.people_house,
          cookies: enrolledData.cookies,
          cereals: enrolledData.cereals,
          basic_basket: enrolledData.basic_basket,
          familiar_help: enrolledData.familiar_help,
          way_familiar_help: enrolledData.way_familiar_help,
          another_help: enrolledData.another_help,
          necesity_scale: enrolledData.necesity_scale,
          necesity_description: enrolledData.necesity_description,
          approved: enrolledData.approved,
          approved_at: enrolledData.approved_at,
          user_approved: enrolledData.user_approved,
          created_at: enrolledData.created_at,
        }));

        setEnrolleds(data);
        setTableData({
          currentPage: response.data.current_page,
          totalData: response.data.total,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSelected, search, column, order, perPage, filters]);

  useEffect(() => {
    loadDatatable();
  }, [loadDatatable]);

  const handleSearch = useCallback((value) => {
    setSearch(value);
    setPageSelected(1);
  }, []);

  const handleChangePage = useCallback((type, datatableParams) => {
    setPageSelected(datatableParams.page);
    setColumn(datatableParams.sortField);
    setOrder(datatableParams.sortOrder);
    setPerPage(datatableParams.sizePerPage);
  }, []);

  const handleClearFilter = useCallback(() => {
    setPageSelected(1);
    setShowFilter(false);
    if (formFilterRef.current) {
      formFilterRef.current.reset();
    }
    loadDatatable();
  }, [loadDatatable]);

  const handleSubmitFilters = useCallback((data: IFilterData) => {
    setFilters(data);
    setPageSelected(1);
    setShowFilter(false);
  }, []);

  const handleLoadParamFilter = useCallback(
    (data: IFilterData) => {
      handleSubmitFilters(data);
    },
    [handleSubmitFilters]
  );

  const handleLoadParamEdit = useCallback(
    (refreshTable: boolean) => {
      if (refreshTable) {
        loadDatatable();
      }
    },
    [loadDatatable]
  );

  const handleQuiz = useCallback(async (studentData: IEnrolledData) => {
    setEnrolledSelected(studentData);
    setShowQuiz(true);
  }, []);

  const handleTurn = useCallback(async (studentData: IEnrolledData) => {
    setEnrolledSelected(studentData);
    setShowTurn(true);
  }, []);

  const handleMailRegistered = useCallback(
    async (studentData: IEnrolledData) => {
      setShowMail(true);
      try {
        await api.get(`enrolleds/mails/${studentData.id}/registered`);
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Erro al enviar el correo!',
        });
      }
      setShowMail(false);
    },
    []
  );

  const handleExcelButtonClick = useCallback(async () => {
    try {
      setLoading(true);
      await api
        .get(`/enrolleds/export`, {
          params: {
            column,
            order,
            search,
            ...filters,
          },
          responseType: 'blob',
        })
        .then((response) => {
          const urlData = generateUrlBlob(response);
          const link = document.createElement('a');
          link.href = urlData;
          link.download = `turns.xlsx`;
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(urlData);
            link.remove();
          }, 100);
        })
        .catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'Error al descargar el archivo',
          });
        })
        .finally(() => setLoading(false));
    } catch (err) {
      //
    }
  }, [column, order, search, filters]);

  const buttons = useCallback(
    (cell: number, row: IEnrolledData) => {
      return (
        <Dropdown>
          <Dropdown.Toggle />
          <Dropdown.Menu>
            <Dropdown.Item>
              <button
                type="button"
                onClick={() => handleQuiz(row)}
                className="border-0 d-flex flex-col bg-transparent"
              >
                <MdGrading size={24} color="#3624f5" />
                <span className="ps-2">Encuesta</span>
              </button>
            </Dropdown.Item>
            <Dropdown.Item>
              <button
                type="button"
                onClick={() => handleTurn(row)}
                className="border-0 d-flex flex-col bg-transparent"
              >
                <FaUserClock size={24} color="#f39742" />
                <span className="ps-2">Turnos</span>
              </button>
            </Dropdown.Item>
            <Dropdown.Item>
              <button
                type="button"
                onClick={() => handleMailRegistered(row)}
                className="border-0 d-flex flex-col bg-transparent"
              >
                <MdOutlineMarkEmailRead size={24} color="#2ebb33" />
                <span className="ps-2">Reenviar correo</span>
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
    },
    [handleQuiz, handleTurn, handleMailRegistered]
  );

  const columns = useMemo<ColumnDescription[]>(() => {
    return [
      {
        dataField: 'index',
        text: '#',
        width: 10,
      },
      {
        dataField: 'id',
        text: ' ',
        formatter: buttons,
      },
      {
        dataField: 'period',
        text: 'Periodo',
        sort: false,
      },
      {
        dataField: 'lastname',
        text: 'Alumno',
        sort: true,
        formatter: (cell: number, row) => {
          return (
            <div className="d-flex flex-column">
              <div>
                {row.lastname}, <span className="fw-bold">{row.name}</span>
              </div>
              <div className="d-flex flex-row">
                <ColumnHighlight value={row.enrollment} color="purple" />
                {row.registered ? (
                  <ColumnHighlight value="Matriculado" color="green" />
                ) : (
                  ''
                )}
                {row.external ? (
                  <ColumnHighlight value="Externo" color="blue" />
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        },
      },
      {
        dataField: 'created_at',
        text: 'Inscripto en',
        sort: true,
        formatter: (cell: number, row) => {
          return format(parseISO(row.created_at), 'dd/MM/yyyy HH:mm');
        },
      },
      {
        dataField: 'user_approved',
        text: 'Evaluado por',
        sort: true,
        formatter: (cell: number, row) => {
          return row.approved !== null ? (
            <div className="d-flex flex-column">
              <div>{row.user_approved}</div>
              <div className="d-flex flex-row">
                {row.approved ? (
                  <ColumnHighlight value="Bolsón" color="blue" />
                ) : (
                  <ColumnHighlight value="Galletas y cereales" color="red" />
                )}
              </div>
            </div>
          ) : (
            ''
          );
        },
      },
      {
        dataField: 'approved_at',
        text: 'Fecha de evaluación',
        sort: true,
        formatter: (cell: number, row) => {
          return row.approved_at
            ? format(parseISO(row.approved_at), 'dd/MM/yyyy HH:mm')
            : '';
        },
      },
    ];
  }, [buttons]);

  return (
    <>
      <div className="py-5 px-sm-5">
        <div className="row">
          <div className="col-12 px-4 overflow-auto d-flex flex-column justify-content-between">
            <DataTable
              columns={columns}
              data={enrolleds}
              page={tableData.currentPage}
              sizePerPage={perPage}
              totalSize={tableData.totalData}
              onFilterClick={() => setShowFilter(true)}
              onSearch={handleSearch}
              onTableChange={handleChangePage}
              loading={loading}
              defaultSorted={[
                {
                  dataField: 'created_at',
                  order: 'desc',
                },
              ]}
              searchable
              showFilterButton
              showExcelButton
              onExcelButonClick={handleExcelButtonClick}
            />
          </div>
        </div>
      </div>
      <Quiz
        show={showQuiz}
        onHide={() => setShowQuiz(false)}
        enrolledParams={enrolledSelected}
        onRefreshTable={handleLoadParamEdit}
      />
      <Turns
        show={showTurn}
        onHide={() => setShowTurn(false)}
        enrolled_id={enrolledSelected.id}
      />
      <Loading show={showMail} message="Enviando correo..." />
      <Filters
        show={showFilter}
        onHide={() => setShowFilter(false)}
        onLoadFilterParam={handleLoadParamFilter}
        onFilterClean={() => handleClearFilter()}
      />
      <Loading show={loading} message="Cargando datos..." />
    </>
  );
};

export default Table;

import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import api from '../services/api';

export interface User {
  id: number;
  name: string;
  email: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ASAUAP:token');
    const user = localStorage.getItem('@ASAUAP:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, password }: SignInCredentials) => {
      try {
        const response = await api.post<AuthState>(`login`, {
          email,
          password,
        });

        const { token, user } = response.data;

        localStorage.setItem('@ASAUAP:token', token);
        localStorage.setItem('@ASAUAP:user', JSON.stringify(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });

        history.push(`${process.env.PUBLIC_URL}/dashboard`);
      } catch (error) {
        Swal.fire(
          'Opss...',
          'Los datos informados no estan correctos',
          'error'
        );
      }
    },
    [history]
  );

  const signOut = useCallback(async () => {
    api.post(`auth/logout`);

    localStorage.removeItem('@ASAUAP:token');
    localStorage.removeItem('@ASAUAP:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@ASAUAP:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  const params = useMemo(
    () => ({
      signIn,
      signOut,
      updateUser,
      user: data.user,
    }),
    [signIn, signOut, updateUser, data.user]
  );

  return <AuthContext.Provider value={params}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

import Container from '~/components/Container';

interface IProps {
  enrolleds: {
    enrolleds: number;
    enabledBasketBasic: number;
    notEnabledBasketBasic: number;
    notEvaluated: number;
  };
}

const Enrolleds: React.FC<IProps> = ({ enrolleds }) => {
  const series = useMemo<number[]>(
    () => [
      enrolleds.enabledBasketBasic,
      enrolleds.notEnabledBasketBasic,
      enrolleds.notEvaluated,
    ],
    [enrolleds]
  );

  const options = useMemo(
    () => ({
      options: {
        labels: ['Bolsón', 'Galletas y cereales', 'No evaluados'],
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '9px',
              },
              value: {
                fontSize: '26px',
                fontWeight: 600,
              },
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
        theme: {
          palette: 'palette8',
        },
      },
    }),
    []
  );

  return (
    <Container>
      <h4>Inscriptos</h4>
      <div className="row">
        <div className="col-12 col-md-6">
          <Chart
            options={options.options}
            series={series}
            type="donut"
            width="100%"
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-10">Habilitados para bolsón</div>
            <div className="col-2">
              <h6>{enrolleds.enabledBasketBasic}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-10">Habilitados para galletas y cereales</div>
            <div className="col-2">
              <h6>{enrolleds.notEnabledBasketBasic}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-10">No evaluados</div>
            <div className="col-2">
              <h6>{enrolleds.notEvaluated}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <h6>Total de inscriptos</h6>
            </div>
            <div className="col-2">
              <h6>{enrolleds.enrolleds}</h6>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Enrolleds;

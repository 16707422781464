import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

import Container from '~/components/Container';

interface IProps {
  enrolledsBasketBasic: {
    enabledBasicBasket: number;
    notEnabledBasicBasket: number;
  };
}

const EnrolledsBasketBasic: React.FC<IProps> = ({ enrolledsBasketBasic }) => {
  const options = useMemo(
    () => ({
      options: {
        labels: ['Habilitados', 'No habilitador'],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
        theme: {
          palette: 'palette8',
        },
      },
      series: [
        enrolledsBasketBasic.enabledBasicBasket,
        enrolledsBasketBasic.notEnabledBasicBasket,
      ],
    }),
    [
      enrolledsBasketBasic.enabledBasicBasket,
      enrolledsBasketBasic.notEnabledBasicBasket,
    ]
  );

  return (
    <Container>
      <h4>Habilitados para el bolsón</h4>
      <div className="row">
        <div className="col-12 col-md-6">
          <Chart
            options={options.options}
            series={options.series}
            type="donut"
            width="100%"
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-10">Habilitados</div>
            <div className="col-2">
              <h6>{enrolledsBasketBasic.enabledBasicBasket}</h6>
            </div>
          </div>
          <div className="row">
            <div className="col-10">No habilitado</div>
            <div className="col-2">
              <h6>{enrolledsBasketBasic.notEnabledBasicBasket}</h6>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EnrolledsBasketBasic;

import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #ffffff;
    border-radius: 10px;

    iframe {
      min-height: 95vh;

      ::-webkit-scrollbar {
        background: transparent;
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #d2d2d2;
        border-radius: 4px;
      }
    }

    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #414141;
    }

    label {
      font-weight: 500;
      color: #414141;
    }

    .close {
      font-size: 24px;
      border: none;
      position: absolute;
      top: 20px;
      right: 40px;
      font-size: 30px;

      .sr-only {
        display: none;
      }
    }

    .buttons-group {
      button {
        width: 121px;
        height: 40px;
        border-radius: 10px;
        border: 2px solid transparent;
      }

      button[type='button'] {
        color: #d9d9d9;
        font-weight: 500;
        border-color: #d9d9d9;
      }

      button[type='submit'] {
        color: #ffffff;
        font-weight: bold;
        background: #2e90fd;
      }
    }
  }
`;

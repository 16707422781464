import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '~/pages/Login';
import ForgotPassword from '~/pages/ForgotPassword';
import ResetPassword from '~/pages/ResetPassword';
import Logout from '~/pages/Logout';
import Dashboard from '~/pages/Dashboard';
import Students from '~/pages/Students';
import Enrolleds from '~/pages/Enrolleds';
import EnrollmentPeriods from '~/pages/EnrollmentPeriods';
import Deliveries from '~/pages/Deliveries';
import DeliveriesHome from '~/pages/Deliveries/Home';
import Users from '~/pages/Users';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/reset-password/:token`}
        exact
        component={ResetPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/logout`}
        isPrivate
        component={Logout}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/students`}
        exact
        isPrivate
        component={Students}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/enrollments-periods`}
        exact
        isPrivate
        component={EnrollmentPeriods}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/enrolleds`}
        exact
        isPrivate
        component={Enrolleds}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/deliveries`}
        exact
        isPrivate
        component={Deliveries}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/deliveries/:delivery_id`}
        exact
        isPrivate
        component={DeliveriesHome}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/users`}
        exact
        isPrivate
        component={Users}
      />
    </Switch>
  );
};

export default routes;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Tab, Tabs } from 'react-bootstrap';
import api from '~/services/api';
import Select, { IOption } from '~/components/Select';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import Input from '~/components/Input';

import { Modal } from './styles';

interface IStudentData {
  id: number;
  enrollment: string;
  name: string;
  lastname: string;
  career: string;
  doc_type_id: number;
  document: string;
  registered: string;
  external: string;
  nacionality: string;
  road: string;
  number: string;
  complement: string;
  phone: string;
  email: string;
  colporteur: string;
  colporteur_leader: string;
}
interface IModalProps {
  show: boolean;
  onHide: () => void;
  studentParams: IStudentData;
  onRefreshTable: (refreshTable: boolean) => void;
}

interface IDocumentTypeData {
  id: number;
  doc_type: string;
}

const FormEdit: React.FC<IModalProps> = ({
  show,
  onHide,
  onRefreshTable,
  studentParams,
}) => {
  const formStudentRef = useRef<FormHandles>(null);
  const [documentTypes, setDocumentTypes] = useState<IOption[]>([]);

  const handleDocumentsType = useCallback(async () => {
    const responseDocumentTypes = await api.get<IDocumentTypeData[]>(
      'students/document-types'
    );

    const dataDocumentTypes: IOption[] = responseDocumentTypes.data.map(
      (documentType) => ({
        id: documentType.id,
        value: documentType.doc_type,
        selected: false,
      }),
      []
    );

    setDocumentTypes(dataDocumentTypes);
    return dataDocumentTypes;
  }, []);

  useEffect(() => {
    handleDocumentsType();
  }, [handleDocumentsType]);

  const handleSubmitEditCertification = useCallback(
    async (data: IStudentData) => {
      try {
        formStudentRef.current?.setErrors({});

        const schema = Yup.object().shape({
          enrollment: Yup.string().required('Matrícula es obligatorio'),
          career: Yup.string().required('Carrera es obligatorio'),
          name: Yup.string().required('Nombre obligatorio'),
          lastname: Yup.string().required('Apellido obligatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        studentParams.id
          ? await api.put(`students/${studentParams.id}`, data)
          : await api.post(`students/`, data);

        onHide();

        if (onRefreshTable) {
          onRefreshTable(true);
        }

        studentParams.id
          ? Toast.fire({
              icon: 'success',
              title: 'Registro cambiado con suceso!',
            })
          : Toast.fire({
              icon: 'success',
              title: 'Registro añadido con suceso!',
            });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formStudentRef.current?.setErrors(errors);
        } else if (error?.response.data?.message) {
          Swal.fire('Opss...', error?.response.data?.message, 'error');
        } else {
          Swal.fire('Opss...', 'Erro ao guardar los datos', 'error');
        }
      }
    },
    [onHide, onRefreshTable, studentParams.id]
  );

  const [keyTab, setKeyTab] = useState('home');
  const handleSelectTab = useCallback((key) => {
    setKeyTab(key || '');
  }, []);

  function formulario() {
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={keyTab}
        onSelect={handleSelectTab}
        className="mb-3"
      >
        <Tab eventKey="home" title="Dados cadastrais">
          <div className="col-ml-3">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <label>Número de alumno *</label>
                <Input type="text" name="enrollment" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Carrera *</label>
                <Input type="text" name="career" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Nombre *</label>
                <Input type="text" name="name" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Apellido *</label>
                <Input type="text" name="lastname" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Tipo de documento</label>
                <Select
                  name="doc_type_id"
                  placeholder="Selecione"
                  options={documentTypes}
                  className="input"
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label>Documento</label>
                <Input type="text" name="document" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Telefone</label>
                <Input type="phone" name="phone" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Email</label>
                <Input type="email" name="email" />
              </div>
            </div>
          </div>
        </Tab>

        <Tab eventKey="profile" title="Dirección">
          <div className="col-ml-3">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <label>Calle</label>
                <Input type="text" name="road" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Número</label>
                <Input type="text" name="number" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Depto/Casa</label>
                <Input type="text" name="complement" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Nacionalidad</label>
                <Input type="text" name="nacionality" />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Colportor</label>
                <Select
                  name="colporteur"
                  options={[
                    { id: 0, value: 'No', selected: false },
                    { id: 1, value: 'Sí', selected: false },
                  ]}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label>Lider del Corportaje</label>
                <Input type="text" name="colporteur_leader" />
              </div>
            </div>
          </div>
        </Tab>
      </Tabs>
    );
  }

  return (
    <Modal show={show} onHide={onHide} close size="lg">
      <Form
        ref={formStudentRef}
        initialData={studentParams}
        onSubmit={handleSubmitEditCertification}
        className="p-4"
      >
        <Modal.Header className="border-none" closeButton>
          {studentParams.id ? (
            <h4>Editar datos del alumno</h4>
          ) : (
            <h4>Añadir alumno</h4>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="tabs-box">{formulario()}</div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Cancelar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Guardar
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormEdit;

import React, { useCallback, useEffect, useState } from 'react';

import api from '~/services/api';
import generateUrlBlob from '~/utils/generateUrlBlob';

import Loading from '../Loading';
import { Modal } from './styles';

interface IModalProps {
  url: string;
  header: string;
  show: boolean;
  onHide: () => void;
}

const ModalFile = ({ url, header, show, onHide }: IModalProps) => {
  const [showLoading, setShowLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');

  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  useEffect(() => {
    setImageUrl('');
    setPdfUrl('');
    if (url) {
      setShowLoading(true);
      api
        .get(`${url}`, {
          responseType: 'blob',
        })
        .then((response) => {
          if (response.headers['content-type'] === 'application/pdf') {
            setPdfUrl(generateUrlBlob(response));
          } else {
            setImageUrl(generateUrlBlob(response));
          }
        })
        .catch(() => {
          handleHide();
        })
        .finally(() => setShowLoading(false));
    }
  }, [url, handleHide]);

  return (
    <>
      <Modal show={show} onHide={handleHide} close size="xl">
        <Modal.Header>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {imageUrl && (
            <div className="relative">
              <image href={imageUrl} className="w-100 h-1/3 p-10" />
            </div>
          )}
          {pdfUrl && (
            <iframe src={pdfUrl} title={header} className="w-100 h-100" />
          )}
          {showLoading && (
            <div className="p-5">
              <Loading show={showLoading} message="Generando el PDF." />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="border-none">
          <div className="buttons-group col-12 my-2 d-flex justify-content-end">
            <button
              type="button"
              onClick={onHide}
              className="btn btn-secondary me-2"
            >
              Cerrar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFile;

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

interface IContainer {
  show: boolean;
}

interface INav {
  show: boolean;
}

interface IButtonOpen {
  show: boolean;
}

interface IBackgroundOpacity {
  show: boolean;
}

interface IMenu {
  active: boolean;
  selected: boolean;
}

export const Container = styled.div<IContainer>`
  transition-duration: 0.3s;
  width: 100%;
  height: 78px;

  .group-language {
    min-width: 50px;

    .language {
      transition-duration: 0.3s;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: 0;
    }
    .language.show {
      opacity: 1;
      visibility: visible;
      position: relative;
    }
  }

  .logo {
    transition-duration: 0.3s;
    overflow: hidden;

    img {
      width: 35px;
      margin-left: 4px;
    }
  }

  div.content {
    z-index: 400;
    position: fixed;
    top: 0;
    transition-duration: 0.3s;
    width: 100%;
    background: #e8e6e0;
    height: 58px;

    > div {
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;

      > div {
        .menu {
          width: 100%;
          height: calc(100vh - 58px);
          background: #e8e6e0;
          position: absolute;
          transition-duration: 0.3s;
          top: 58px;
          left: ${(props) => (props.show ? '0' : '-1000px')};
          overflow-y: auto;
          overflow-x: hidden;

          ::-webkit-scrollbar-thumb {
            background-color: #373737;
          }

          > div {
            min-height: calc(100vh - 58px);
            display: flex;
            flex-direction: column;
          }
        }
      }

      ::-webkit-scrollbar-thumb {
        transition-duration: 0.3s;
        background-color: #373737;
        opacity: 0;
      }

      :hover {
        ::-webkit-scrollbar-thumb {
          background-color: #373737;
          opacity: 1;
        }
      }
    }
  }

  div.footer {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.show ? '1' : '0')};
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    overflow: hidden;
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;

    p {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #bcbcbc;

      b {
        color: #818181;
      }
    }
  }

  @media screen and (min-width: 992px) {
    width: 100px;
    height: 100vh;

    .logo {
      width: ${(props) => (props.show ? '100vh' : '40px')};
    }

    .logout {
      span {
        visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
      }
    }

    div.content {
      width: ${(props) => (props.show ? '320px' : '100px')};
      min-height: 100vh;
      height: auto;

      > div {
        height: 100vh;

        > div {
          min-height: 100vh;

          .menu {
            position: unset;
            left: 0;
            overflow-y: unset;
            overflow-x: unset;
            height: unset;

            > div {
              min-height: calc(100vh - 140px);
            }
          }
        }
      }
    }

    div.footer {
      width: ${(props) => (props.show ? '267px' : '50px')};
      margin-bottom: unset;

      p {
        width: 267px;
      }
    }
  }
`;

export const BackgroundOpacity = styled.button<IBackgroundOpacity>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(5, 5, 5, 0.21);
  z-index: 2;
  opacity: ${(props) => (props.show ? '1' : '0')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  border: none;
  cursor: default;
`;

export const Nav = styled.nav<INav>`
  .user-container {
    width: ${(props) => (props.show ? '267px' : '50px')};
    transition-duration: 0.3s;
    overflow: hidden;
    margin: 35px auto 0;

    .user-card {
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? '1' : '0')};
      visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
      background: #d3ac4d;
      border-radius: 12px;
      width: 267px;

      p {
        color: #d0d0d0;
      }

      a {
        color: #5e5e5e;
      }

      button {
        color: #ff033f;
      }
    }

    hr {
      transition-duration: 0.3s;
      opacity: ${(props) => (props.show ? '1' : '0')};
      visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
      background: #303030;
    }

    a.active {
      background: #373737 !important;
    }
  }

  @media screen and (min-width: 992px) {
    border-radius: 0px 20px 20px 0px;
  }
`;

export const ButtonOpen = styled.button<IButtonOpen>`
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 4px;
  width: 50px;
  height: 50px;
  border: none;
  background: transparent;

  > div {
    width: 20px;
    height: 20px;

    span {
      display: block;
      background-color: #8692a6;
      height: 2px;
      width: 100%;
      border-radius: 5px;
      margin-left: auto;
      transition-duration: 0.3s;

      :nth-child(2) {
        width: 50%;
        margin: 5px 0 5px auto;
      }

      ${(props) =>
        props.show &&
        css`
          background-color: #cccccc;

          :nth-child(1) {
            transform: rotateZ(45deg);
          }

          :nth-child(2) {
            opacity: 0;
          }

          :nth-child(3) {
            margin-top: -14px;
            transform: rotateZ(-45deg);
          }
        `}
    }
  }

  :focus {
    box-shadow: none !important;
  }

  @media screen and (min-width: 992px) {
    right: -30px;
    top: 160px;
    background: #d3ac4d;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1) !important;
    border-radius: 50%;

    svg {
      transition-duration: 0.3s;
      transform: rotateZ(${(props) => (props.show ? '180deg' : '0')});
    }

    :focus {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1) !important;
    }
  }
`;

export const MenuButton = styled(NavLink)<IMenu>`
  overflow: hidden;
  width: ${(props) => (props.active ? '267px' : '50px')};
  height: 50px;
  transition-duration: 0.3s;
  background: ${(props) =>
    props.active ? 'rgba(203, 150, 20, 0.15);' : 'transparent'};
  border-radius: 12px;
  border: none;
  color: #936800;

  .arrow {
    transition-duration: 0.3s;
    transform: rotateZ(0);
  }

  span {
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  }

  :hover {
    background: rgba(203, 150, 20, 0.72);
    color: #eeeee4;
  }
`;

import React from 'react';

import { AuthProvider } from './Auth';
import { PermissionProvider } from './Permission';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <PermissionProvider>{children}</PermissionProvider>
  </AuthProvider>
);

export default AppProvider;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Default from './Default';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} component={Default} />
    </Switch>
  );
};

export default routes;

import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (min-width: 992px) {
    .body-content {
      width: calc(100% - 100px);
    }
  }
`;

export const Header = styled.header`
  @media screen and (min-width: 992px) {
    height: 78px;
  }
`;

export const Content = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 10px;

  .company-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 20px;
    color: #414141;
  }

  @media screen and (min-width: 992px) {
    height: 78px;
    position: unset;
  }
`;

export const Welcome = styled.div`
  transition-duration: 0.3s;

  > div {
    a,
    button,
    div {
      transition-duration: 0.2s;
      width: 150px;
      color: #414141;
      font-size: 18px;
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 78px;
    position: unset;
    right: unset;

    .no-border {
      :after {
        display: none;
      }
    }

    > div {
      min-height: unset;

      a,
      button {
        width: unset;
      }
    }
  }
`;

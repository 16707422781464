import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from '~/components/Loading';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Modal, ListGroup } from './styles';

interface IEnrolledData {
  id: number;
  marital_status: boolean;
  coexistence_group: boolean;
  people_house: boolean;
  cookies: boolean;
  cereals: boolean;
  basic_basket: boolean;
  familiar_help: boolean;
  way_familiar_help: boolean;
  another_help: boolean;
  necesity_scale: number;
  necesity_description: string;
  approved: string;
  approved_at: string;
  user_approved: string;
  created_at: string;
}

interface IModalProps {
  show: boolean;
  onHide: () => void;
  enrolledParams: IEnrolledData;
  onRefreshTable: (refreshTable: boolean) => void;
}

const Quiz: React.FC<IModalProps> = ({
  show,
  onHide,
  onRefreshTable,
  enrolledParams,
}) => {
  const [showLoading, setLoading] = useState(false);

  const handleApprove = useCallback(
    async (approve: boolean) => {
      try {
        onHide();
        setLoading(true);
        await api.put(`enrolleds/${enrolledParams.id}/approve`, {
          approved: approve,
        });

        if (onRefreshTable) {
          onRefreshTable(true);
        }

        Toast.fire({
          icon: 'success',
          title: 'Datos cambiados com sucesso!',
        });
        setLoading(false);
      } catch (error) {
        Swal.fire('Opss...', 'Erro al guardar la información', 'error');
      }
    },
    [onHide, onRefreshTable, enrolledParams.id]
  );

  return (
    <>
      <Modal show={show} onHide={onHide} close size="lg">
        <Modal.Header className="border-none" closeButton>
          <h4>Encuesta del alumno</h4>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item>
              <div className="row">
                <div className="col-12 col-sm-6">
                  Estado civil: <span>{enrolledParams.marital_status}</span>
                </div>
                <div className="col-12 col-sm-6">
                  Cuantos componen el nucleo familiar:{' '}
                  <span>{enrolledParams.people_house}</span>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              Grupo de convivencia (con quien vive):{' '}
              <span>{enrolledParams.coexistence_group}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="row">
                <div className="col-12 col-sm-4">
                  Bolsón de alimentos:{' '}
                  <span>{enrolledParams.basic_basket ? `Sí` : `No`}</span>
                </div>
                <div className="col-12 col-sm-4">
                  Galletas: <span>{enrolledParams.cookies ? `Sí` : `No`}</span>
                </div>
                <div className="col-12 col-sm-4">
                  Cereales: <span>{enrolledParams.cereals ? `Sí` : `No`}</span>
                </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              Recibes ayuda de un familiar para los alimentos:{' '}
              <span>{enrolledParams.familiar_help ? `Sí` : `No`}</span>
              {enrolledParams.familiar_help ? (
                <span>
                  {' '}
                  ({enrolledParams.way_familiar_help ? `Total` : `Parcial`})
                </span>
              ) : null}
            </ListGroup.Item>
            <ListGroup.Item>
              Recibes donación de algún feligrés, iglesia o grupo misionero:{' '}
              <span>{enrolledParams.another_help ? `Sí` : `No`}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Escala de necesidad: <span>{enrolledParams.necesity_scale}</span>
            </ListGroup.Item>
            <ListGroup.Item>
              Descriptión: <span>{enrolledParams.necesity_description}</span>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group">
            <button
              type="button"
              className="btn-not-approve me-2"
              onClick={() => handleApprove(false)}
            >
              Autorizar para galletas y cereales unicamente
            </button>
            <button
              type="button"
              className="btn-approve"
              onClick={() => handleApprove(true)}
            >
              Autorizar para Bolsón
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Loading show={showLoading} message="Guardando los datos..." />
    </>
  );
};

export default Quiz;

import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';

import Container from '~/components/Container';

interface IEnrolleds {
  series: Array<number>;
  categories: Array<string>;
}

interface IProps {
  chartData: IEnrolleds;
}

const EnrolledsByDay: React.FC<IProps> = ({ chartData }) => {
  const series = useMemo<ApexAxisChartSeries>(
    () =>
      [
        {
          name: 'Inscriptos',
          data: chartData.series,
        },
      ] as ApexAxisChartSeries,
    [chartData]
  );

  const options = useMemo(
    () => ({
      options: {
        chart: {
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
        theme: {
          palette: 'palette8',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: chartData.categories,
          title: {
            text: 'Día/Mes',
          },
        },
      },
    }),
    [chartData.categories]
  );

  return (
    <Container>
      <h4>Inscriptos por día</h4>
      <div className="row">
        <div className="col-12">
          <Chart
            options={options.options}
            series={series}
            type="line"
            width="100%"
          />
        </div>
      </div>
    </Container>
  );
};

export default EnrolledsByDay;

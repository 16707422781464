import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Modal } from './styles';
import InputFile from '~/components/InputFile';
import Loading from '~/components/Loading';

interface IStudentData {
  id: number;
  enrollment: string;
  name: string;
  lastname: string;
  career: string;
  doc_type_id: number;
  document: string;
  registered: string;
  external: string;
  nacionality: string;
  road: string;
  number: string;
  complement: string;
  phone: string;
  email: string;
  colporteur: string;
  colporteur_leader: string;
}
interface IModalProps {
  show: boolean;
  onHide: () => void;
  onRefreshTable: (refreshTable: boolean) => void;
}

const FormUpload: React.FC<IModalProps> = ({
  show,
  onHide,
  onRefreshTable,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [fileData, setFileData] = useState<File>({} as File);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: IStudentData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          file: Yup.string().required('El archivo es obligatorio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = new FormData();

        if (fileData) {
          formData.append('file', fileData);
        }

        await api.post(`imported-students`, formData);

        onHide();

        if (onRefreshTable) {
          onRefreshTable(true);
        }

        Toast.fire({
          icon: 'success',
          title: 'Estamos procesando su pedido. Puede tardar hasta 5 min.',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Falla al cargar el archivo en el servidor',
            'error'
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [onHide, onRefreshTable, fileData]
  );

  const handleChangeFile = useCallback((file) => {
    setFileData(file);
  }, []);

  return (
    <Modal show={show} onHide={onHide} close>
      <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
        <Modal.Header className="border-none" closeButton>
          <h4>Cargar datos de los alumnos</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="tabs-box">
            <div className="col-12">
              <div className="row">
                <div className="col-12 mb-3">
                  <label>Archivo Excel</label>
                  <InputFile
                    name="file"
                    onChange={handleChangeFile}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="buttons-group">
            <button type="button" className="btn-cancel me-2" onClick={onHide}>
              Cancelar
            </button>
            <button type="submit" className="btn-submit ms-2">
              Guardar
            </button>
          </div>
        </Modal.Footer>
      </Form>
      <Loading show={loading} message="Cargando datos..." />
    </Modal>
  );
};

export default FormUpload;
